import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DynamicStatusService {
  private statuses$ = new Subject<string>();
  private clearStatuses$ = new Subject<boolean>();

  get statuses() {
    return this.statuses$.asObservable();
  }

  get clearStatuses() {
    return this.clearStatuses$.asObservable();
  }

  addStatus(status: string): void {
    this.statuses$.next(status);
  }

  addDelayedStatus(status: string, delay = 1500): void {
    setTimeout(() => {
      this.statuses$.next(status);
    }, delay);
  }

  clear(): void {
    this.clearStatuses$.next(true);
  }
}
