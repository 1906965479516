import { Component } from '@angular/core';
import { SSODrivers } from '@core/authentication/sso/sso.type';
import { AuthService } from '@core';
import { CommonService } from '@shared/services/common.service';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss'],
})
export class SsoComponent {
  protected waitingForSSO = false;

  constructor(
    private auth: AuthService,
    private commonService: CommonService
  ) {}


  loginViaSSO(driver: SSODrivers) {
    this.waitingForSSO = true;
    this.auth.redirectToLoginSSO(driver)?.subscribe({
      next: url => {
        if (url) {
          window.location.href = url.authUrl;
        }
      },
      error: () => {
        this.waitingForSSO = false;
        this.commonService.openErrorSnackBar('Failed to login via SSO');
      },
      complete: () => {
      },
    });
  }
}
