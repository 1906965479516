import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'img[appImgFallback]',
})
export class ImageFallbackDirective {
  @Input() appImgFallback = '';

  constructor(private eRef: ElementRef) {}

  @HostListener('error')
  loadFallbackOnError() {
    const element: HTMLImageElement = this.eRef.nativeElement;
    element.src = this.appImgFallback;
  }
}
