<app-spinner *ngIf="!message"></app-spinner>
<div *ngIf="message" class="d-flex align-items-center controls">
  <span *ngIf="message.date" class="m-r-8">{{ message.date | formattedDate }}</span>
  <ng-container *ngIf="!hideControls">
    <app-good-prompt-button *ngIf="bot" [message]="message" [bot]="bot"></app-good-prompt-button>
    <app-star-button
      [canStar]="canStar"
      [isStarred]="message.favorite"
      [onToggle]="toggleMessageStar.bind(this, message)"
    ></app-star-button>
    <span [matTooltip]="'Copy to clipboard'">
      <button
        mat-icon-button
        color="white"
        (click)="copyMessage(message)"
        data-cy="copy-to-clipboard"
      >
        <mat-icon class="icon">file_copy</mat-icon>
      </button>
    </span>
    <app-show-original-resources
      [originalSources]="message.originalSourceDetails || []"
      *ngIf="
        message.author === 'assistant' &&
        message.originalSourceDetails &&
        message.originalSourceDetails.length > 0
      "
    ></app-show-original-resources>
  </ng-container>
</div>
