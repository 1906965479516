import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TQuery } from '../types/general.types';
import { IGNORE_ERRORS_KEY } from '@core';
import { StringHelper } from "../../helpers/string.helper";

@Injectable({ providedIn: 'root' })
export class BaseApiService {
  protected apiPath: string | null = null;
  private readonly baseUrl = `${environment.backendURL}/api/slr`;

  constructor(private http: HttpClient) {}

  //
  // Note: API auth uses global app Interceptor
  //
  protected getFullUrl(endpoint: string, query?: TQuery): string {
    const baseUrl = this.apiPath ? `${this.baseUrl}/${this.apiPath}` : this.baseUrl;
    const queryString = StringHelper.buildQueryParams(query);
    return `${baseUrl}/${endpoint}${queryString}`;
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders().set(IGNORE_ERRORS_KEY, 'true');
  }

  protected get<T>(url: string, query?: TQuery): Observable<T> {
    return this.http.get<T>(this.getFullUrl(url, query), { headers: this.getHeaders() });
  }

  protected post<B, R>(url: string, body: B): Observable<R> {
    return this.http.post<R>(this.getFullUrl(url), body, { headers: this.getHeaders() });
  }

  protected put<B, R>(url: string, body: B): Observable<R> {
    return this.http.put<R>(this.getFullUrl(url), body, { headers: this.getHeaders() });
  }

  protected patch<T>(url: string, body: T): Observable<T> {
    return this.http.patch<T>(this.getFullUrl(url), body, { headers: this.getHeaders() });
  }

  protected delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(this.getFullUrl(url), { headers: this.getHeaders() });
  }
}
