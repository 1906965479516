<div class="main">
  <div class="d-flex align-items-center form-field-full messages-form">
    <div class="w-full content-container">
      <!-- <app-local-sources [botId]="bot._id"></app-local-sources> -->
      <div class="bot-results-container" *ngIf="botMessageService.countMessages() !== 0">
        <app-bot-results [bot]="bot"></app-bot-results>
        <app-dynamic-status></app-dynamic-status>

        <div class="container">
          <button
                  *ngIf="!isSearching && !isWriting && botMessageService.countMessages()"
                  mat-button
                  class="m-b-8 regenerate-btn"
                  color="secondary"
                  data-cy="regenerate-msg"
                  (click)="regenerateMessage()">
            <mat-icon class="small-icon">autorenew</mat-icon>
            Regenerate
          </button>
        </div>
      </div>
    </div>

    <app-conversation-input
                            [fixedToBottom]="true"
                            *ngIf="!hideInput"
                            [promptHints]="bot.promptHints || []"
                            [filterButton]="allowFilterButton"
                            [botId]="bot._id"
                            (filtersClick)="openFilters.emit($event)"
                            [isWriting]="isWriting"
                            (messageSubmitted)="sendMessage($event.message, $event.files, $event.sourceIds)"
                            (writingAbort)="stopWriting()">
    </app-conversation-input>
  </div>
</div>
