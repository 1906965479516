import { Injectable } from '@angular/core';
import { BehaviorSubject, iif, merge, Observable, of } from 'rxjs';
import { catchError, map, share, switchMap, tap } from 'rxjs/operators';
import { TokenService } from './token.service';
import { LoginService } from './login.service';
import { filterObject, isEmptyObject } from './helpers';
import { User } from './interface';
import { SSOLoginService } from '@core/authentication/sso/sso-login.service';
import { SSODrivers } from '@core/authentication/sso/sso.type';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private user$ = new BehaviorSubject<User>({});
  private username: string | undefined;
  private userId: string | undefined;
  private change$ = merge(
    this.tokenService.change(),
    this.tokenService.refresh().pipe(switchMap(() => this.refresh()))
  ).pipe(
    switchMap(() => this.assignUser()),
    share()
  );

  constructor(
    private loginService: LoginService,
    private tokenService: TokenService,
    private _ssoLoginService: SSOLoginService,
  ) {}

  init() {
    return new Promise<void>(resolve => this.change$.subscribe(() => resolve()));
  }

  change() {
    return this.change$;
  }

  check() {
    return this.tokenService.valid();
  }

  login(username: string, password: string, rememberMe = false): Observable<{
    required2Fa?: boolean,
    authenticated?: boolean
  }> {
    return this.loginService.login(username, password, rememberMe)
      .pipe(
        map(res => {
          this.userId = res.userId;
          this.username = username;
          this.tokenService.set({ access_token: res.token!, token_type: 'bearer', username });
          if (res.requires2FA) {
            return { required2Fa: true };
          }
          const checkResult = this.check();
          return { authenticated: checkResult };
        }),
      );
  }

  redirectToLoginSSO(driver: SSODrivers) {
    return this._ssoLoginService.setDriver(driver).initLogin();
  }

  verify2FaCode(token: string) {
    return this.loginService.verify2FaCode(this.userId!, token);
  }

  refresh() {
    return this.loginService
      .refresh(filterObject({ refresh_token: this.tokenService.getRefreshToken() }))
      .pipe(
        catchError(() => of(undefined)),
        tap(token => this.tokenService.set(token)),
        map(() => this.check())
      );
  }

  logout() {
    return this.loginService.logout().pipe(
      tap(() => this.tokenService.clear()),
      map(() => !this.check())
    );
  }

  user() {
    return this.user$.pipe(share());
  }

  menu() {
    return iif(() => this.check(), this.loginService.menu(), of([]));
  }

  private assignUser() {
    if (!this.check()) {
      return of({}).pipe(tap(user => this.user$.next(user)));
    }

    if (!isEmptyObject(this.user$.getValue())) {
      return of(this.user$.getValue());
    }

    return this.loginService.me(this.username).pipe(tap(user => this.user$.next(user)));
  }

  public setUser(user: User) {
    this.user$.next(user);
  }

  public setToken(token: string, username = this.username) {
    this.tokenService.set({ access_token: token, token_type: 'bearer', username });
  }
}
