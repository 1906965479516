import { Component, Input, OnDestroy } from '@angular/core';
import { TSourceItem } from '@core/interfaces/TSourceItem';
import { SourcesService } from '@shared/services/sources.service';
import { TExternalReference, TSourceEmbedding } from '@core/api/sources/types';
import { CommonService } from '@shared/services/common.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ExternalReferenceFormModalComponent } from '../source-embedings/external-reference-form-modal/external-reference-form-modal.component';
import { ConfirmationModalComponent } from '../../../modals/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-source-references',
  templateUrl: './source-references.component.html',
  styleUrls: ['./source-references.component.scss'],
})
export class SourceReferencesComponent implements OnDestroy {
  protected source: TSourceItem | undefined;

  protected isLoading = false;
  private _subs: Subscription[] = [];

  constructor(
    private _sourcesService: SourcesService,
    private commonService: CommonService,
    public dialog: MatDialog
  ) {}

  @Input() set sourceItem(source: TSourceItem) {
    this.source = source;
  }

  @Input() sourceEmbedding: TSourceEmbedding | undefined;

  isAttached(reference: TExternalReference) {
    return !(
      this.sourceEmbedding?.externalReferences?.some(value => value === reference._id) ?? false
    );
  }

  addReferenceId(referenceId: string) {
    if (this.sourceEmbedding && this.sourceEmbedding.externalReferences) {
      this.sourceEmbedding.externalReferences.push(referenceId);
    }
  }

  removeReferenceId(referenceId: string) {
    if (this.sourceEmbedding && this.sourceEmbedding.externalReferences) {
      this.sourceEmbedding.externalReferences = this.sourceEmbedding.externalReferences.filter(
        id => id !== referenceId
      );
    }
  }

  loadReferences() {
    this.isLoading = true;
    this._sourcesService.getSource(this.source!._id, (res: TSourceItem) => {
      this.source = res;
      this.isLoading = false;
    });
  }

  deleteReference(reference: TExternalReference) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: { text: `Are you sure you want to delete this reference?` },
    });

    dialogRef.componentInstance.actionCallback.subscribe(() => {
      this._subs.push(
        this._sourcesService
          .deleteExternalReference(this.source!._id, reference._id!)
          .subscribe(() => {
            this.commonService.openSuccessSnackBar('Reference deleted');
            this.loadReferences();
          })
      );
      dialogRef.close();
    });
  }

  openModal(reference?: TExternalReference) {
    const dialRef = this.dialog.open(ExternalReferenceFormModalComponent, {
      width: '100%',
      data: { source: this.source, reference },
    });
    dialRef.componentInstance.referenceChanged.subscribe(() => {
      if (reference) {
        this.commonService.openSuccessSnackBar('Reference updated');
      } else {
        this.commonService.openSuccessSnackBar('Reference added');
      }
      this.loadReferences();
    });
  }

  attachReference(reference: TExternalReference) {
    this.isLoading = true;
    this._subs.push(
      this._sourcesService
        .attachReference(this.sourceEmbedding!._id, reference._id!)
        .subscribe(() => {
          this.commonService.openSuccessSnackBar('Reference attached');
          this.isLoading = false;
          this.addReferenceId(reference._id!);
        })
    );
  }

  detachReference(reference: TExternalReference) {
    this.isLoading = true;
    this._subs.push(
      this._sourcesService
        .detachReference(this.sourceEmbedding!._id, reference._id!)
        .subscribe(() => {
          this.commonService.openSuccessSnackBar('Reference detached');
          this.isLoading = false;
          this.removeReferenceId(reference._id!);
        })
    );
  }

  setResults(results: TExternalReference[]) {
    if (this.source) {
      this.source!.externalReferences = results;
    }
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }
}
