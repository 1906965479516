import { Component, Input } from '@angular/core';
import * as FileSaver from 'file-saver';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { CommonService } from '@shared/services/common.service';
import { IGNORE_ERRORS_KEY } from '@core';

@Component({
  selector: 'app-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.scss'],
})
export class FileDownloadComponent {
  @Input({ required: true }) link: string | undefined;
  @Input({ required: true }) fileName: string | undefined;

  constructor(
    private http: HttpClient,
    private commonService: CommonService
  ) {
  }

  download() {
    const headers = new HttpHeaders().set(IGNORE_ERRORS_KEY, 'true');
    this.http.get(this.link!, { headers, responseType: 'blob' })
      .pipe(
        catchError(() => {
          this.commonService.openErrorSnackBar('Material not available');
          return of(null);
        }),
      )
      .subscribe(blob => {
        if (blob) {
          FileSaver.saveAs(blob, this.fileName);
        }
      });
  }
}
