import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';

import { environment } from '@env/environment';

import { MetadataService, MetadataSlugs } from '@shared/services/metadata.service';
import { StringUtils } from '@shared/utils/stringUtils';
import { defaultAppName, defaultVersion } from 'app/constants/defaults';

@Component({
  selector: 'app-version-dialog',
  templateUrl: './version-dialog.component.html',
  styleUrls: ['./version-dialog.component.scss'],
})
export class VersionDialogComponent implements OnInit, OnDestroy {
  public version = '';
  public appName = '';
  private _subs: Subscription[] = [];
  private observer: MutationObserver | undefined;

  public brandSidebarLogoPath = './assets/brands/prod/sidebarLogo.png';
  public brandSidebarLogoClass = 'sidebar-logo';
  public brandLogoPath = StringUtils.brandResource('logo');
  public brandLogoPathLight = StringUtils.brandResource('logoLight');
  public brandLogo: string | undefined;
  public brandLogoWidth = environment.logoWidthPx ?? '220px';

  constructor(
    private _metadataService: MetadataService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  get isDarkMode(): boolean {
    return this.document.documentElement.classList.contains('theme-dark');
  }

  ngOnInit(): void {
    this.updateLogo();
    
    // Watch for changes to the HTML element's class attribute
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'class') {
          this.updateLogo();
        }
      });
    });

    this.observer.observe(this.document.documentElement, {
      attributes: true,
      attributeFilter: ['class']
    });

    this._subs.push(
      this._metadataService.get<string>(MetadataSlugs.Version).subscribe({
        next: metadata => {
          if (metadata.value) {
            this.version = metadata.value;
          } else {
            this.version = defaultVersion;
          }
        },
        error: () => {
          this.version = defaultVersion;
        },
      })
    );
    this._subs.push(
      this._metadataService.get<string>(MetadataSlugs.AppName).subscribe({
        next: metadata => {
          if (metadata.value) {
            this.appName = metadata.value;
          } else {
            this.appName = defaultAppName;
          }
        },
        error: () => {
          this.appName = defaultAppName;
        },
      })
    );
  }

  public ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private updateLogo(): void {
    this.brandLogo = this.isDarkMode ? this.brandLogoPath : this.brandLogoPathLight;
  }
}
