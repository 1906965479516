import { TCurrentConversation } from '@core/interfaces/TResultsItem';

import { TAdaptedBotConversation, TAdaptedMessage, TReturnedFileData } from '../types/types';
import { MessageParser } from '@shared/services/bots/helpers/messageParser';
import { BaseAdapter } from '@shared/services/bots/adapters/BaseAdapter';
import { ArrayUtils } from '@shared/utils/arrayUtils';
import { QuizQuestion } from '@core/interfaces/TBot';

export class BotResultsLegacyAdapter extends BaseAdapter {
  adapt(data: TCurrentConversation): TAdaptedBotConversation {
    return {
      id: data._id,
      content: data.content,
      messages: this.adaptMessages(data, data.uploadedFiles as TReturnedFileData[]),
      language: data.language,
      favorite: data.favorite,
      type: 'results',
    };
  }

  protected adaptMessages(
    msg: TCurrentConversation,
    files: TReturnedFileData[] = []
  ): TAdaptedMessage[] {
    if ((msg.questions?.length ?? 0) === 0) {
      return this.adaptRegularResults(msg, files);
    } else {
      return this.adaptQuizResults(msg);
    }
  }

  private adaptRegularResults(msg: TCurrentConversation, files: TReturnedFileData[]) {
    const results: TAdaptedMessage[] = [];

    msg?.messages.forEach(originalMsg => {
      const data = MessageParser.parseMaterialsWithReferences(originalMsg);
      results.push(data);
    });
    if (results.length > 0) {
      results[0].files = files;
    }
    return results;
  }

  private adaptQuizResults(msg: TCurrentConversation) {
    const results: TAdaptedMessage[] = [];

    msg?.messages.forEach(originalMsg => {
      try {
        const textData = JSON.parse(originalMsg.text) as {
          type: string;
          data: QuizQuestion;
        };
        if (textData.type === 'quiz') {
          const lastResult = ArrayUtils.last(results);
          const lastPar = ArrayUtils.last(lastResult?.paragraphs);
          if (lastPar) {
            results.push({
              _id: originalMsg._id,
              date: originalMsg.date!,
              author: 'assistant',
              type: 'message',
              paragraphs: [
                {
                  text: '',
                  quizQuestions: [textData.data],
                },
              ],
              sources: [],
              originalSourceDetails: [],
              favorite: originalMsg.favorite,
            });
          }

          return;
        } else {
          return;
        }
      } catch (e) {}

      const data = MessageParser.parseMaterialsWithReferences(originalMsg);
      results.push(data);
    });

    return results;
  }
}
