import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TBotItem } from '@core/interfaces/TBotItem';
import { BotsService } from '@shared/services/bots.service';
import { Subscription } from 'rxjs';
import { CommonService } from '@shared/services/common.service';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './duplicate-bot-modal.component.html',
  styleUrls: ['./duplicate-bot-modal.component.scss'],
})
export class DuplicateBotModalComponent implements OnInit, OnDestroy {

  protected name = '';
  private _subs: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { bot: TBotItem },
    private _dialogRef: MatDialogRef<DuplicateBotModalComponent>,
    private _botsService: BotsService,
    private _commonService: CommonService,
  ) {
  }

  ngOnInit(): void {
    this.name = this.data.bot.name + ' (copy)';
  }

  get isValid() {
    return (this.name.length >= 4) && this.name != this.data.bot.title;
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }

  cancel() {
    this._dialogRef.close();
  }

  confirm() {
    this._subs.push(this._botsService.duplicateBot(this.name, this.data.bot._id).subscribe(() => {
      this._commonService.openSuccessSnackBar('Bot duplicated successfully');
      this.cancel();
    }));
  }
}
