import { Inject, Injectable, Injector } from '@angular/core';
import { SSODrivers } from '@core/authentication/sso/sso.type';
import { SSOMicrosoft } from '@core/authentication/sso/drivers/sso-microsoft';
import { ISSODriver } from '@core/authentication/sso/drivers/sso-driver.interface';
import { SSOOcta } from "@core/authentication/sso/drivers/sso-octa";

@Injectable({
  providedIn: 'root',
})
export class SSOLoginService {
  private _driver: ISSODriver | undefined;
  constructor(@Inject(Injector) private injector: Injector) {}
  setDriver(driver: SSODrivers): this {
    switch (driver) {
      case 'microsoft':
        this._driver = this.injector.get(SSOMicrosoft);
        return this;
      case 'octa':
        this._driver = this.injector.get(SSOOcta);
        return this;
      default:
        throw new Error('Driver not supported');
    }

  }

  initLogin() {
    return this._driver?.initLogin();
  }
}
