<div class="new-item-modal">
  <h3 class="mat-h3" *ngIf="!isLoading">Confirmation</h3>
  <div class="mat-description" *ngIf="!isLoading" [innerHTML]="data.text"></div>

    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

  <div *ngIf="!isLoading" class="d-flex align-items-center justify-content-between m-t-16">
    <button
      mat-raised-button
      color="accent"
      [disabled]="isLoading"
      (click)="cancel()">
      Cancel
    </button>
    <button
      mat-raised-button
      color="accent"
      [disabled]="isLoading"
      (click)="confirm()">
      Yes, confirm
    </button>
  </div>
</div>
