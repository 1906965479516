<h3 class="p-24 p-l-32 mat-h3">Edit your API access settings</h3>
<div class="p-24 p-t-0 d-flex flex-col w-full">
  <h3 class="mat-h3">RISE settings</h3>
  <div class="m-b-24 m-t-8 suffix">To use RISE publications and clinical trials as your source data,
    you must provide
    credentials to RISE.
  </div>
  <form class="form-field-full" [formGroup]="editECRAccessForm">
    <h4 class="m-b-8 m-t-0">E-mail</h4>
    <div class="form-field-container">
      <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
        <input
               id="ecr-email-input"
               matInput
               type="text"
               formControlName="name"
               placeholder="Enter your email" />
      </mat-form-field>
    </div>

    <h4 class="m-b-8 m-t-0">Password</h4>
    <div class="form-field-container">
      <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
        <input
               id="ecr-password-input"
               matInput
               type="password"
               formControlName="password"
               placeholder="Enter your password" />
      </mat-form-field>
    </div>

    <button
            class="m-b-24"
            mat-raised-button
            color="accent"
            [disabled]="!editECRAccessForm.valid"
            (click)="saveECRAccessSettings()">
      Save
    </button>
  </form>
  <h3 class="mat-h3">Confluence settings</h3>
  <div class="m-b-24 m-t-8 suffix">To import Confluence pages as your source data, you have to
    provide API settings.</div>
  <form class="form-field-full" [formGroup]="confluenceSettings">
    <h4 class="m-b-8 m-t-0">Username</h4>
    <div class="form-field-container">
      <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
        <input
               id="confluence-username-input"
               matInput
               formControlName="username"
               placeholder="Enter your Confluence username" />
      </mat-form-field>
    </div>
    <h4 class="m-b-8 m-t-0">API token</h4>
    <div class="m-b-24 m-t-8 suffix">To get API token go to Confluence Manage Account page then
      Security then Create and Manage API tokens</div>

    <div class="form-field-container">
      <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
        <input
               id="confluence-api-token-input"
               matInput
               type="password"
               formControlName="password"
               placeholder="Enter your API token" />
      </mat-form-field>
    </div>
    <h4 class="m-b-8 m-t-0">Url</h4>
    <div class="m-b-24 m-t-8 suffix">The URL for your Confluence instance including:
      <code>/wiki/rest/api</code>
    </div>
    <div class="form-field-container">
      <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
        <input
               id="confluence-url-input"
               matInput
               formControlName="url"
               placeholder="Enter your Confluence URL" />
      </mat-form-field>
    </div>

    <button
            class="m-b-24"
            mat-raised-button
            color="accent"
            [disabled]="!confluenceSettings.valid"
            (click)="saveConfluenceSettings()">
      Save
    </button>
  </form>


  <h3 class="mat-h3">Veeva settings</h3>
  <div class="m-b-24 m-t-8 suffix">To import Veeva data, you have to provide API settings.</div>
  <form class="form-field-full" [formGroup]="veevaSettings">
    <h4 class="m-b-8 m-t-0">Username</h4>
    <div class="form-field-container">
      <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
        <input
               id="veeva-username-input"
               matInput
               formControlName="username"
               placeholder="Enter your Veeva username" />
      </mat-form-field>
    </div>
    <h4 class="m-b-8 m-t-0">Password</h4>

    <div class="form-field-container">
      <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
        <input
               id="veeva-password-input"
               matInput
               type="password"
               formControlName="password"
               placeholder="Enter your Veeva password" />
      </mat-form-field>
    </div>
    <h4 class="m-b-8 m-t-0">Url</h4>
    <div class="m-b-24 m-t-8 suffix">The URL for your Veeva instance</div>
    <div class="form-field-container">
      <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
        <input
               id="veeva-url-input"
               matInput
               formControlName="url"
               placeholder="Enter your Veeva URL" />
      </mat-form-field>
    </div>

    <button
            class="m-b-24"
            mat-raised-button
            color="accent"
            [disabled]="!veevaSettings.valid"
            (click)="saveVeevaSettings()">
      Save
    </button>
  </form>
</div>