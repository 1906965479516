<div class="p-48 centered-content" *ngIf="!conversationBegan">
  <div class="centered-text">
    <img [src]="dashboardLogo" [appImgFallback]="fallbackLogo" alt="OneRay logo" class="logo" />
    <h1>{{ getGreeting() }}!</h1>
    <p *ngIf="dashboardContent" [innerHTML]="dashboardContent"></p>
  </div>
  <app-conversation-input
    [isExtended]="true"
    [isDisabled]="!selectedBot"
    [promptHints]="selectedBot?.promptHints || []"
    [botId]="selectedBot?._id || ''"
    (messageSubmitted)="submit($event)"
  ></app-conversation-input>
  <app-bot-selector (botSelected)="selectedBot = $event"></app-bot-selector>
</div>

<div [hidden]="!conversationBegan">
  <div *ngIf="selectedBot">
    <app-quiz
      *ngIf="selectedBot.botType === 'quizBot'; else conversationBot"
      [currentMessage]="message!"
      [bot]="selectedBot"
    ></app-quiz>
    <ng-template #conversationBot>
      <app-conversation-bot
        [allowFilterButton]="false"
        [hideInput]="!conversationBegan"
        [bot]="selectedBot"
      ></app-conversation-bot>
    </ng-template>
  </div>
</div>
