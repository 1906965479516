import { getPathToSourceItem } from './routing';
import { Language } from '@core/interfaces/language';

import { TSourceDetail } from '@core/interfaces/TMessage';

export interface TOrderedSource {
  order: number;
  data: TSourceDetail;
}

export const isOrderedSource = (obj: TOrderedSource | TSourceDetail): obj is TOrderedSource => {
  return obj && (obj as TOrderedSource).order !== undefined;
};

export class ReferencesUtils {
  private static getFileLink = (fileLink: string): string => {
    if (fileLink.startsWith('http://') || fileLink.startsWith('https://')) {
      return fileLink;
    } else {
      return `https://${fileLink}`;
    }
  };

  static getLink = (source: TSourceDetail | null | undefined): string => {
    if (!source) {
      return '';
    }
    if (!source.fileLink) {
      if (!source.folder || source.folder.length === 0) {
        return '';
      }
      return getPathToSourceItem(source.folder[0], source._id);
    }
    return this.getFileLink(source.fileLink);
  };



  static getReferenceLabelForLanguage = (language: Language | undefined): string => {
    switch (language) {
      case 'en':
        return 'References';
      case 'de':
        return 'Referenzen';
      case 'fr':
        return 'Références';
      case 'es':
        return 'Referencias';
      default:
        return 'References';
    }
  };
}
