import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { AuthService, User } from '@core';

import { userFields, UserFormField } from '../../../constants/user';
import { ConfirmationModalComponent } from '../../components/modals/confirmation-modal/confirmation-modal.component';
import { CommonService } from '@shared/services/common.service';
import { UserService } from '@shared/services/user.service';
import {
  getFieldAddConfirmationMessage,
  getFieldEditConfirmationMessage,
} from '@shared/utils/messages';
import { areTwoFieldsEqual, isPasswordValid } from '@shared/utils/validation';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {
  user!: User;
  isNameEditingDisabled = true;
  isDisplayNameEditingDisabled = true;
  isPasswordEditingDisabled = true;
  allowedUserFormFields = UserFormField;

  public editProfileForm = this.fb.nonNullable.group({
    name: [''],
    displayName: [''],
    oldPassword: [''],
    newPassword: [''],
    newPasswordRepeated: [''],
  });

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private _commonService: CommonService,
    private _authService: AuthService,
    private _userService: UserService
  ) {}

  ngOnInit(): void {
    this._authService.user().subscribe(user => {
      this.user = user;
      this.editProfileForm.setValue({
        name: user.name || '',
        displayName: user.displayName || '',
        oldPassword: '',
        newPassword: '',
        newPasswordRepeated: '',
      });
    });
    this.editProfileForm.valueChanges.subscribe((formData: any) => {
      this.isNameEditingDisabled = !formData.name || formData.name === this.user.name;
      this.isDisplayNameEditingDisabled =
        !formData.displayName || formData.displayName === this.user.displayName;
      this.isPasswordEditingDisabled =
        !formData.oldPassword || !formData.newPassword || !formData.newPasswordRepeated;
      const areNewPasswordFieldsEqual = areTwoFieldsEqual(
        formData.newPassword,
        formData.newPasswordRepeated
      );
      if (!areNewPasswordFieldsEqual) {
        this.editProfileForm.get('newPasswordRepeated')?.setErrors({
          ...(this.editProfileForm.get('newPasswordRepeated')?.errors || {}),
          notEqual: true,
        });
      }
      if (
        !isPasswordValid(formData.newPasswordRepeated) &&
        this.editProfileForm.controls.newPasswordRepeated.touched
      ) {
        this.editProfileForm.get('newPasswordRepeated')?.setErrors({
          ...(this.editProfileForm.get('newPasswordRepeated')?.errors || {}),
          notValid: true,
        });
      }
      if (areNewPasswordFieldsEqual && isPasswordValid(formData.newPasswordRepeated)) {
        this.editProfileForm.get('newPasswordRepeated')?.setErrors(null);
      }
    });
  }

  public get oldPassword() {
    return this.editProfileForm.get('oldPassword')!;
  }

  public get newPasswordRepeated() {
    return this.editProfileForm.get('newPasswordRepeated')!;
  }

  public saveField(formFieldName: UserFormField, isMetadata = false): void {
    const fieldLabel = userFields[formFieldName].label;
    const oldValue = this.user[formFieldName];
    const newValue = this.editProfileForm.value[formFieldName];
    let dialogText: string;
    if (oldValue) {
      dialogText = getFieldEditConfirmationMessage(oldValue, newValue!, fieldLabel);
    } else {
      dialogText = getFieldAddConfirmationMessage(newValue!, fieldLabel);
    }
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: { text: dialogText },
    });
    const dialogSubscription = dialogRef.componentInstance.actionCallback.subscribe(() => {
      if (isMetadata) {
        this._userService.updateCurrentUserMetadata(
          {
            slug: userFields[formFieldName].apiSlug as string,
            value: this.editProfileForm.value[formFieldName]!,
          },
          () => {
            dialogRef.close();
            this._commonService.openSuccessSnackBar(`Your ${fieldLabel} was edited`);
            dialogSubscription.unsubscribe();
          }
        );
      } else {
        this._userService.updateCurrentUser(
          { [userFields[formFieldName].apiSlug]: this.editProfileForm.value[formFieldName]! },
          () => {
            dialogRef.close();
            this._commonService.openSuccessSnackBar(`Your ${fieldLabel} was edited`);
            dialogSubscription.unsubscribe();
          }
        );
      }
    });
  }

  public savePassword(): void {
    this._userService.updateCurrentUserPassword(
      this.editProfileForm.value.oldPassword!,
      this.editProfileForm.value.newPassword!,
      () => {
        this._commonService.openSuccessSnackBar('Your password was updated');
        this.editProfileForm.reset();
      },
      () => {
        this.editProfileForm.controls.oldPassword.setErrors({ incorrect: true });
      }
    );
  }
}
