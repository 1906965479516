import { Component, OnDestroy, OnInit } from '@angular/core';
import { TMaterialSourceFolder, TSourceMaterial } from '@core/api/materials/types';
import { SourceMaterialsService } from '@shared/services/source-materials.service';
import { Subscription } from 'rxjs';
import { ConfirmationModalComponent } from '../../components/modals/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from '@shared/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-materials-folders',
  templateUrl: './materials-folders.component.html',
  styleUrls: ['./materials-folders.component.scss'],
})
export class MaterialsFoldersComponent implements OnInit, OnDestroy {

  protected isLoading = false;

  public materials: TSourceMaterial[] | undefined;
  private _subs: Subscription[] = [];
  protected folders: TMaterialSourceFolder[] = [];
  protected selectedFolder: TMaterialSourceFolder | null = null;

  constructor(
    protected sourceMaterialService: SourceMaterialsService,
    protected dialog: MatDialog,
    private _commonServices: CommonService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this._subs.push(this.route.url.subscribe(() => {
        this.navigateToFolder();
      }),
    );
  }


  ngOnInit(): void {
    this.isLoading = true;
    this._subs.push(this.sourceMaterialService.folders$.subscribe(value => {
      this.folders = value;
      this.isLoading = false;
      this.navigateToFolder();
    }));
    this._subs.push(this.sourceMaterialService.selectedFolder$.subscribe(value => {
      this.selectedFolder = value;
    }));
    this.sourceMaterialService.getFoldersList();
  }

  navigateToFolder() {
    const folderId = this.route.snapshot.paramMap.get('folder_id');
    if (!folderId) {
      this.selectedFolder = null;
      return;
    }
    const foundFolder = this.folders.find(item => item._id === folderId) ?? null;
    this.sourceMaterialService.setFolder(foundFolder);
  }

  protected selectFolder(folder: TMaterialSourceFolder | null) {
    const params = folder ? { folder_id: folder._id } : {};
    this.router.navigate(['sources/materials', params]);
  }

  protected deleteFolder(item: TMaterialSourceFolder) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: { text: `Are you sure you want to delete "${item.name}"?` },
    });
    const dialogSubscription = dialogRef.componentInstance.actionCallback.subscribe(() => {
      this.sourceMaterialService.deleteFolder(item._id!, () => {
        dialogRef.close();
        this.sourceMaterialService.setFolder(null);
        this.selectFolder(null);
        this._commonServices.openSuccessSnackBar(`Folder "${item.name}" was deleted`);
      });

      dialogSubscription.unsubscribe();
    });
  }

  ngOnDestroy() {
    this._subs.forEach(value => value.unsubscribe());
  }
}
