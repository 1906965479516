<div class="p-24 p-t-0 d-flex flex-col align-items-baseline">
  <h3 class="mat-h3">App name</h3>
  <div class="form-field-container">
    <label for="app-name-input" class="form-field-label">Application Name</label>
    <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
      <input
             id="app-name-input"
             [(ngModel)]="appName"
             matInput
             type="text"
             required
             placeholder="Enter application name here" />
    </mat-form-field>
  </div>
  <button mat-raised-button class="m-t-16" color="accent" (click)="saveAppName()">Save</button>
</div>

<div class="p-24 p-t-0 d-flex flex-col align-items-baseline">
  <h3 class="mat-h3">App version</h3>
  <div class="form-field-container">
    <label for="app-version-input" class="form-field-label">Application Version</label>
    <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
      <input
             id="app-version-input"
             [(ngModel)]="version"
             matInput
             type="text"
             required
             placeholder="Enter version text here" />
    </mat-form-field>
  </div>
  <button mat-raised-button class="m-t-16" color="accent" (click)="saveAppVersion()">Save</button>
</div>