import { IPaginationDto } from '../../../../api/dtos/pagination.dto';

export interface ITableConfig {
  displayedColumns: string[];
  columnLabels?: Record<string, string>;
  columnClasses?: Record<string, string | string[]>;
  pagination?: IPaginationConfig;
}

export interface IPaginationConfig {
  page?: number;
  limit?: number;
  total?: number;
  pageSizeOptions?: number[];
}

export const INITIAL_PAGINATION_SIZE = [10, 20, 50, 100];

export const INITIAL_PAGINATION_CONFIG: IPaginationDto = {
  page: 1,
  limit: INITIAL_PAGINATION_SIZE[0],
};
