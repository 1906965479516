<div class="project-settings">
  <div class="row align-items-center justify-content-between m-b-24">
    <div>
      <h4 class="m-b-0">{{ selectedItem.title }}</h4>
      <div class="project-description">{{ selectedItem.description }}</div>
    </div>
    <button
      *allowedRole="'admin'"
      mat-raised-button
      class="m-l-16 control"
      color="accent"
      (click)="openEditModal()"
    >
      Edit project
    </button>
  </div>
  <div class="project-settings__menu">
    <ul>
      <li [ngClass]="getTabCSS(allowedTabs.SOURCES)" (click)="selectTab(allowedTabs.SOURCES)">
        <mat-icon svgIcon="sources"></mat-icon>
        <span>Project Sources</span>
      </li>
      <li [ngClass]="getTabCSS(allowedTabs.BOTS)" (click)="selectTab(allowedTabs.BOTS)">
        <mat-icon svgIcon="bots"></mat-icon>
        <span>Agents</span>
      </li>
      <li [ngClass]="getTabCSS(allowedTabs.USERS)" (click)="selectTab(allowedTabs.USERS)">
        <mat-icon svgIcon="users"></mat-icon>
        <span>Users</span>
      </li>
    </ul>
  </div>
  <div class="project-settings__content">
    <div *ngIf="isTabVisible(allowedTabs.BOTS)">
      <app-project-bots [selectedProject]="selectedItem"></app-project-bots>
    </div>
    <div *ngIf="isTabVisible(allowedTabs.SOURCES)">
      <app-project-sources [selectedProject]="selectedItem"></app-project-sources>
    </div>
    <div *ngIf="isTabVisible(allowedTabs.USERS)">
      <app-project-users [selectedProject]="selectedItem"></app-project-users>
    </div>
  </div>
</div>
