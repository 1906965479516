<div class="embedding-header" *ngIf="embeddings.length > 0">
  <h3>Source Embeddings Information</h3>
</div>

<div class="embeddings-container">
  <div class="embedding-item" *ngFor="let embedding of embeddings; let i = index">
    <!-- Source ID and status header -->
    <div class="embedding-header-row">
      <div class="embedding-id">Embedding #{{ i + 1 }}</div>
      <div class="status-badge" [ngClass]="getStatusClass(embedding.status)">
        {{ embedding.status }}
      </div>
    </div>

    <!-- Content section with summary -->
    <div class="info-section full-width">
      <h4>Content</h4>
      <div class="content-text">{{ embedding.content }}</div>
      <div class="summary-text" *ngIf="embedding.summary">
        <h5>Summary</h5>
        <p>{{ embedding.summary }}</p>
      </div>
    </div>

    <div class="two-col-container">
      <!-- Keywords section on left -->
      <div class="info-section" *ngIf="embedding.keywords && embedding.keywords.length > 0">
        <h4>Keywords</h4>
        <div class="keywords-container">
          <mat-chip-listbox aria-label="Keywords" [selectable]="false">
            <mat-chip-option *ngFor="let keyword of embedding.keywords" [selectable]="false">
              {{ keyword }}
            </mat-chip-option>
          </mat-chip-listbox>
        </div>
      </div>

      <!-- Information section on right -->
      <div class="info-section">
        <h4>Information</h4>
        <div class="info-content">
          <div class="date-grid">
            <div class="date-item" *ngIf="embedding.publicationDate">
              <span class="date-label">Publication Date:</span>
              <span>{{ formatDate(embedding.publicationDate) }}</span>
            </div>
            <div class="date-item" *ngIf="embedding.expirationDate">
              <span class="date-label">Expiration Date:</span>
              <span>{{ formatDate(embedding.expirationDate) }}</span>
            </div>
          </div>

          <div class="section-divider"></div>

          <!-- References button -->
          <div class="reference-section">
            <button (click)="openReferenceModal(embedding)" mat-flat-button color="accent">
              References
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Embedding separator (except after the last one) -->
    <div class="embedding-separator" *ngIf="i < embeddings.length - 1"></div>
  </div>
</div>

<div class="no-data" *ngIf="embeddings.length === 0">
  <p>No embedding data available for this source.</p>
</div>
