<form class="form-field-full" [formGroup]="botEditorForm">
  <h3 class="mat-h3 m-t-24">{{ bot.type.replace('Bot', '') }} bot details</h3>
  <div class="row m-b-24">
    <div class="form-field-container col-12">
      <label for="bot-name-input" class="form-field-label">Name</label>
      <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
        <input id="bot-name-input" matInput formControlName="name" placeholder="Bot name"
               required />
      </mat-form-field>
    </div>

    <div class="form-field-container col-12">
      <label for="bot-description-textarea" class="form-field-label">Description</label>
      <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
        <textarea
                  id="bot-description-textarea"
                  matInput
                  placeholder="Bot description"
                  formControlName="description"
                  rows="3"></textarea>
      </mat-form-field>
    </div>

    <ng-template *ngIf="bot.type !== 'quizBot'">
      <div class="form-field-container col-12">
        <label for="bot-presearch-textarea" class="form-field-label">Presearch</label>
        <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
          <textarea
                    id="bot-presearch-textarea"
                    matInput
                    placeholder="Presearch"
                    formControlName="preSearch"
                    rows="2"></textarea>
        </mat-form-field>
      </div>
    </ng-template>

    <div class="form-field-container col-12">
      <label for="bot-instruction-textarea" class="form-field-label d-flex align-items-center">
        Instruction <mat-icon class="icon" matTooltip="{{ infoInstruction }}">info</mat-icon>
      </label>
      <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
        <textarea
                  id="bot-instruction-textarea"
                  matInput
                  placeholder="Instruction"
                  formControlName="instruction"
                  rows="3"></textarea>
      </mat-form-field>
    </div>

    <div class="form-field-container col-12">
      <label for="bot-behaviour-textarea" class="form-field-label d-flex align-items-center">
        Behaviour <mat-icon class="icon" matTooltip="{{ infoBehaviour }}">info</mat-icon>
      </label>
      <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
        <textarea
                  id="bot-behaviour-textarea"
                  matInput
                  placeholder="Behaviour"
                  formControlName="behaviour"
                  rows="3"></textarea>
      </mat-form-field>
    </div>

    <ng-container *ngIf="['summaryBot', 'quizBot'].includes(bot.type)">
      <div class="form-field-container col-12">
        <label for="bot-config-textarea" class="form-field-label d-flex align-items-center">
          Config <mat-icon class="icon" matTooltip="{{ botConfig }}">info</mat-icon>
        </label>
        <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
          <textarea
                    id="bot-config-textarea"
                    matInput
                    placeholder="Config"
                    formControlName="config"
                    rows="3"></textarea>
        </mat-form-field>
      </div>
    </ng-container>

    <div class="form-field-container col-12">
      <label for="bot-model-select" class="form-field-label d-flex align-items-center">Bot
        model</label>
      <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
        <mat-select id="bot-model-select" formControlName="bot_model" placeholder="Select model">
          <mat-option *ngFor="let model of botsModels" [value]="model.id">
            {{ model.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-field-container col-12">
      <label for="bot-status-select" class="form-field-label d-flex align-items-center">Bot
        Status</label>
      <mat-form-field class="gingr-input-wrapper w-full hide-sub" appearance="outline">
        <mat-select id="bot-status-select" formControlName="bot_status" placeholder="Select status">
          <mat-option *ngFor="let status of botStatuses" [value]="status">
            {{ status.replace('_', ' ') | firstUpper }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <app-notice
                *ngIf="
        botsTypesForcedStatus.includes(bot.type) &&
        appGlobalSettings.getSettingsValue().master_bot_status
      "
                [text]="
        'Master status (' +
        appGlobalSettings.getSettingsValue().master_bot_status +
        ') overrides status for this bot type.'
      "></app-notice>
  </div>

  <ng-container *ngIf="bot.type === 'quizBot'">
    <h3 class="mat-h3 m-t-24">Quiz bot extra configurations</h3>
    <div formGroupName="extraConfigurations" class="m-b-24">
      <div class="row" formGroupName="feedback">
        <div class="form-field-container col-12">
          <label for="feedback-behaviour-input" class="form-field-label">Feedback behaviour</label>
          <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
            <input id="feedback-behaviour-input" matInput placeholder="Feedback behaviour"
                   formControlName="behaviour" />
          </mat-form-field>
        </div>

        <div class="form-field-container col-12">
          <label for="feedback-instruction-input" class="form-field-label">Feedback
            instruction</label>
          <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
            <input id="feedback-instruction-input" matInput placeholder="Feedback instruction"
                   formControlName="instruction" />
          </mat-form-field>
        </div>

        <div class="form-field-container col-12">
          <label for="feedback-config-input" class="form-field-label">Feedback config</label>
          <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
            <input id="feedback-config-input" matInput placeholder="Feedback config"
                   formControlName="config" />
          </mat-form-field>
        </div>
      </div>

      <div formGroupName="initMessage">
        <div class="form-field-container">
          <label for="init-message-behaviour-input" class="form-field-label">Init message
            behaviour</label>
          <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
            <input id="init-message-behaviour-input" matInput placeholder="Init message behaviour"
                   formControlName="behaviour" />
          </mat-form-field>
        </div>

        <div class="form-field-container">
          <label for="init-message-instruction-input" class="form-field-label">Init message
            instruction</label>
          <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
            <input id="init-message-instruction-input" matInput
                   placeholder="Init message instruction" formControlName="instruction" />
          </mat-form-field>
        </div>

        <div class="form-field-container">
          <label for="init-message-config-input" class="form-field-label">Init message
            config</label>
          <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
            <input id="init-message-config-input" matInput placeholder="Init message config"
                   formControlName="config" />
          </mat-form-field>
        </div>
      </div>

      <div formGroupName="secondRun">
        <div class="form-field-container">
          <label for="second-run-config-input" class="form-field-label">Second run config</label>
          <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
            <input id="second-run-config-input" matInput placeholder="Second run config"
                   formControlName="config" />
          </mat-form-field>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="d-flex justify-content-between flex-wrap">
    <div *ngIf="bot.type !== 'quizBot'">
      <div class="form-field-container">
        <label class="form-field-label m-l-4 d-flex align-items-center">
          Materials <mat-icon class="icon" matTooltip="{{ materialsInfo }}">info</mat-icon>
        </label>
        <div class="d-flex option-box m-b-24">
          <mat-slider class="gingr-input-wrapper materials-input" min="0" max="10" step="1"
                      discrete>
            <input matInput matSliderThumb formControlName="maximum_resources" />
          </mat-slider>
          <div class="d-flex m-t-12">
            <mat-slide-toggle
                              formControlName="display_resources"
                              class="m-l-16"
                              matTooltip="enable/disable materials" />
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="form-field-container">
        <label class="form-field-label m-l-4 d-flex align-items-center">
          Number of publications
          <mat-icon class="icon" matTooltip="{{ publicationsCountInfo }}">info</mat-icon>
        </label>
        <div class="d-flex option-box m-b-24">
          <mat-slider
                      class="gingr-input-wrapper materials-input"
                      min="0"
                      max="{{ MAX_PUBLICATIONS_LIMIT }}"
                      step="1"
                      discrete>
            <input matInput matSliderThumb formControlName="maximum_publications" />
          </mat-slider>
          <div class="d-flex m-t-12">
            <mat-slide-toggle
                              formControlName="use_all_publications"
                              class="m-l-16"
                              matTooltip="use all publications" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-inner-bots [bot]="bot" *ngIf="bot.type === 'botCreator'"></app-inner-bots>

  <app-talking-avatar-settings [bot]="bot"></app-talking-avatar-settings>

  <h3 class="mat-h3 m-t-48">Other</h3>
  <div class="d-flex flex-col">
    <mat-checkbox formControlName="showOnDashboard">Show on home page</mat-checkbox>
    <mat-checkbox formControlName="useOwnKnowledge">Use own knowledge</mat-checkbox>
  </div>


  <div class="d-flex align-items-center justify-content-end m-t-16">
    <button
            class="action-button"
            mat-raised-button
            color="accent"
            [disabled]="botEditorForm.invalid"
            (click)="saveBotConfig()">
      Save
    </button>
  </div>
</form>