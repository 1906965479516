import { Component, Input, OnDestroy } from '@angular/core';
import { TSourceDetail } from '@core/interfaces/TMessage';
import { MatDialog } from '@angular/material/dialog';
import { SourcePreviewModalComponent } from '../modal/source-preview-modal.component';
import { CommonService } from '@shared/services/common.service';

@Component({
  selector: 'app-source-preview-button',
  templateUrl: './source-preview-button.component.html',
  styleUrls: ['./source-preview-button.component.scss'],
})
export class SourcePreviewButtonComponent {
  @Input() sourceDetail: TSourceDetail | null = null;
  @Input() referencePositionIndex: number | null = null;
  protected isLoading = false;

  constructor(
    private commonService: CommonService,
    private dialog: MatDialog
  ) {}

  protected onButtonClick() {
    if (!this.sourceDetail?._id) {
      this.commonService.openErrorSnackBar('An error occurred while trying to get the source');
      return;
    }

    const data = {
      sourceDetail: this.sourceDetail,
      referencePositionIndex: this.referencePositionIndex,
    };

    this.dialog.open(SourcePreviewModalComponent, {
      data,
      width: '80%',
      height: '80%',
    });
  }
}
