import { Injectable, Injector } from '@angular/core';
import { TSourceItem } from '@core/interfaces/TSourceItem';
import { ISourceOriginDriver } from './drivers/source-origin-driver.interface';
import { VeevaSourceOrigin } from './drivers/veeva-origin';
import { LocalSourceOrigin } from './drivers/local-origin';

@Injectable({
  providedIn: 'root',
})
export class GoToSourceService {
  private source: TSourceItem | undefined;
  private _driver: ISourceOriginDriver | undefined;

  constructor(private injector: Injector) {}

  get driver() {
    if (!this._driver || !this.source) throw new Error('Driver or Source is not set');
    return this._driver;
  }

  setSource(source: TSourceItem) {
    this.source = source;
    const originDriver = this.detectOrigin();
    this._driver = this.injector.get(originDriver);
    this.driver.setSource(source);
  }

  detectOrigin() {
    if (this.source?.metadata.find(it => it.slug === 'veeva_id')) {
      return VeevaSourceOrigin;
    }
    if (this.source?.fileLink) {
      return LocalSourceOrigin;
    }
    throw new Error('Unknown source type');
  }

  hasSourceLink() {
    return this.driver.hasSourceLink(this.source!);
  }

  getSourceUrl() {
    return this.driver.getSourceUrl(this.source!);
  }
}
