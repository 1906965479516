import { HttpParams } from '@angular/common/http';

import { environment } from '@env/environment';

export class StringUtils {
  static brandResource(resource: 'logo' | 'logoLight' | 'logoFile', ext = 'png'): string {
    return `./assets/brands/${environment.brand}/${resource}.${ext}`;
  }

  static brandResourceWithFallback(resource: string, ext = 'png'): string {
    return `./assets/brands/${environment.brand}/${resource}.${ext}`;
  }

  static brandResourceFallbackPath(fallback: string, ext = 'png'): string {
    return `./assets/brands/${environment.brand}/${fallback}.${ext}`;
  }

  static urlParamsToStringArray(items: any[], key: string, param: string): string {
    let params = new HttpParams();
    items.forEach(item => {
      params = params.append(`${param}`, item[key]);
    });
    return params.toString();
  }

  static sanitizeMarkdown(text: string | undefined) {
    if (!text) return '';
    const markdownRegex = /[_*~`]/g;
    return text.replace(markdownRegex, '');
  }

  static splitTextBySentences(text: string, maxChunkSize = 1300): string[] {
    const sentences = text.match(/[^.!?]+[.!?]+/g) || [text];
    const chunks: string[] = [];
    let currentChunk = '';

    for (const sentence of sentences) {
      if ((currentChunk + sentence).length > maxChunkSize) {
        chunks.push(currentChunk.trim());
        currentChunk = sentence;
      } else {
        currentChunk += sentence;
      }
    }

    if (currentChunk) {
      chunks.push(currentChunk.trim());
    }

    return chunks;
  }

  static trimText(text: string, numberOfCharacters: number): string {
    if (text.length <= numberOfCharacters) {
      return text;
    }
    return text.slice(0, numberOfCharacters);
  }

  static getFirstSentence(text: string): string {
    const match = text.match(/[^.!?]*[.!?]/);
    return match ? match[0] : text;
  }

  static fromCamelCase(text: string): string {
    return text.replace(/([A-Z])/g, ' $1').trim();
  }

  static fromSnakeCase(text: string): string {
    return text.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
  }

  static camelToSnakeCase(str: string): string {
    return str.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
  }
}
