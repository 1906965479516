import { ISourceOriginDriver } from './source-origin-driver.interface';
import { Injectable } from '@angular/core';
import { TSourceItem } from '@core/interfaces/TSourceItem';

@Injectable({
  providedIn: 'root',
})
export class LocalSourceOrigin implements ISourceOriginDriver {
  source: TSourceItem | undefined;

  setSource(source: TSourceItem): void {
    this.source = source;
  }

  hasSourceLink(): boolean {
    return !!this.source?.fileLink;
  }

  getSourceUrl(): string | null {
    return this.source?.fileLink || null;
  }
}
