import { TAdaptedBotConversation } from '../types/types';
import { BaseAdapter } from '@shared/services/bots/adapters/BaseAdapter';
import { ArrayUtils } from '@shared/utils/arrayUtils';
import { TConversationStreamData } from '@core/api/conversations/types';
import { QuizQuestion } from '@core/interfaces/TBot';
import { TSourceDetail } from '@core/interfaces/TMessage';

export class QuizBotAnswersAdapter extends BaseAdapter {
  adapt(
    data: TConversationStreamData,
    currentValue: TAdaptedBotConversation,
    sourcesDetails: TSourceDetail[]
  ): TAdaptedBotConversation {
    currentValue.type = 'conversation';
    if (data.type === 'json_answer') {
      const lastMessage = ArrayUtils.last(currentValue?.messages);

      let lastParagraph = ArrayUtils.last(lastMessage?.paragraphs);

      if (!lastParagraph) {
        lastParagraph = { text: '', quizQuestions: [] };
        lastMessage?.paragraphs.push(lastParagraph);
      }
      if (data.data) {
        lastParagraph.quizQuestions = data.data as QuizQuestion[];
      }
      if (!lastMessage) {
        lastMessage!.sources = [];
      }
      lastMessage!.sources = sourcesDetails;
    }
    return currentValue;
  }
}
