import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { TBotItem } from '@core/interfaces/TBotItem';

import { ActivatedRoute, Router } from '@angular/router';
import { BotsService } from '@shared/services/bots.service';
import { CommonService } from '@shared/services/common.service';
import { isNotNullAndDefined } from '@shared/utils/isNotNullAndDefined';
import { NewBotModalComponent } from '../components/gingr-new-items-modals/new-bot-modal/new-bot-modal.component';
import { ConfirmationModalComponent } from '../components/modals/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-bots',
  templateUrl: './bots.component.html',
  styleUrls: ['./bots.component.scss'],
})
export class BotsComponent implements OnInit, OnDestroy {
  public bots: TBotItem[] = [];
  public drawerOpened = true;
  public selectedBot: TBotItem | null = null;
  public botIdFromRoute: string | null = null;

  private _subs: Subscription[] = [];

  constructor(
    private _botsService: BotsService,
    public dialog: MatDialog,
    private _commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this._subs.push(
      this._botsService.getBotsObservable().subscribe(bots => {
        this.bots = bots;
        this._setBotFromRoute();
      })
    );
    this._botsService.fetchBots();
    this._botsService.fetchBotTypes();

    if (this.route.children[0]) {
      this._subs.push(
        this.route.children[0].params.subscribe(params => {
          if (params.id) {
            this.botIdFromRoute = params.id;
            this._setBotFromRoute();
          }
        })
      );
    }
  }

  private _setBotFromRoute(): void {
    if (this.botIdFromRoute && this.bots.length) {
      const bot = this.bots.find(item => item._id === this.botIdFromRoute);
      if (bot && (!this.selectedBot || bot._id !== this.selectedBot._id)) {
        this.selectedBot = bot;
      }
    }
  }

  public addNewItem(): void {
    this.dialog.open(NewBotModalComponent);
  }

  public hasSufix(bot: TBotItem): boolean {
    return isNotNullAndDefined(bot.sufix);
  }

  public selectBot(bot: TBotItem): void {
    this.selectedBot = bot;
    this.router.navigateByUrl(`/bots/${bot._id}`);
  }

  public toggleDrawer(): void {
    this.drawerOpened = !this.drawerOpened;
  }

  public openDeleteModal(bot: TBotItem): void {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: { text: `Are you sure you want to delete "${bot.title}"?` },
    });
    const dialogSubscription = dialogRef.componentInstance.actionCallback.subscribe(() => {
      this._botsService.deleteBot(bot._id, () => {
        dialogRef.close();
        this._commonService.openSuccessSnackBar(`Bot ${bot.title} was deleted`);
      });
      dialogSubscription.unsubscribe();
    });
  }

  public ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }
}
