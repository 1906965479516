<form class="form">
  <mat-form-field class="users-chip-list" appearance="outline">
    <!--    <mat-label>Users</mat-label>-->
    <mat-chip-grid #chipGrid aria-label="Fruit selection">
      <mat-chip-row *ngFor="let user of chosenUsers" (removed)="remove(user)" color="warn">
        {{ user.username }}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
    </mat-chip-grid>
    <input placeholder="Type to search for a user..."
           #chipInput
           [formControl]="searchControl"
           [matAutocomplete]="auto"
           [matChipInputFor]="chipGrid" />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <ng-container *ngIf="!isLoading && !notFound">
        <mat-option
                    *ngFor="let searchedUser of foundUsers"
                    [value]="searchedUser">
          {{ searchedUser.username }}
        </mat-option>
      </ng-container>
      <mat-option *ngIf="notFound" disabled="true">Not found</mat-option>
      <mat-option *ngIf="isLoading" disabled="true">Wait...</mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
<button
        mat-raised-button
        color="accent"
        (click)="emitChosenUsersIds()"
        [disabled]="chosenUsers.length === 0">
  Add users
</button>