import { BaseAdapter } from '@shared/services/bots/adapters/BaseAdapter';
import { ConversationData, Reference } from '@core/interfaces/TResultsItem';
import { TAdaptedBotConversation } from '@shared/services/bots/types/types';
import { ArrayUtils } from '@shared/utils/arrayUtils';
import { ReferenceDetail, TSourceDetail } from '@core/interfaces/TMessage';
import { ReferenceConverter } from '@shared/services/bots/helpers/reference-converter';

export class ReferenceAdapter extends BaseAdapter {
  private converter: ReferenceConverter;

  constructor() {
    super();
    this.converter = new ReferenceConverter();
  }

  adapt(
    data: {
      index: number;
      data: ConversationData;
    },
    currentValue: TAdaptedBotConversation
  ): TAdaptedBotConversation {
    const lastMessage = ArrayUtils.last(currentValue?.messages);

    if (lastMessage) {
      if (!lastMessage.sources) {
        lastMessage.sources = [];
      }
      const sources: TSourceDetail[] = data.data.sources?.map(source => ({
        _id: source.id,
        fileLink: source.fileLink,
        name: this.getReferences(source),
        referenceIndexes: new Map<number, ReferenceDetail>(),
      }));

      lastMessage.sources.push(...sources);

      this.converter.convertWithIndexedParagraph(data.data, lastMessage, data.index);
    }
    currentValue.messages.forEach(message => {
      message.sources = message.sources.filter(source => (source.referenceIndexes?.size ?? 0) > 0);
    });
    return currentValue;
  }

  getReferences(source: Reference): string {
    if (source.references) {
      if (source.references === '') {
        return source.name;
      }
      return source.references;
    }
    return source.name;
  }
}
