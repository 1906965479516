import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TQuery } from '../../api/types/general.types';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class QueryFiltersService {
  private _filters$ = new BehaviorSubject<TQuery | null>(null);

  constructor(private router: Router) {}

  get filters$() {
    return this._filters$.asObservable();
  }

  setFilters(filters: TQuery, addToUrl = true) {
    this._filters$.next(filters);
    if (addToUrl) {
      this.router.navigate([], { queryParams: filters, queryParamsHandling: 'merge' });
    }
  }

  resetFilters() {
    this.router.navigate([], { queryParams: {}, queryParamsHandling: '' }).then(() => {
      this._filters$.next(null);
    });
  }

  clearFilters() {
    this.router.navigate([], { queryParams: {}, queryParamsHandling: '' }).then(() => {
      this._filters$.next({});
    });
  }

  getCurrentFilters(): TQuery {
    return this._filters$.getValue() ?? {};
  }
}
