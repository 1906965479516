<app-talking-avatar #talkingHead (visibleToggle)="cdr.detectChanges()"></app-talking-avatar>

<div class="container" [style]="{height: conversationSizeContainer + 'px'}">
  <div class="column bot-name">
    <app-bot-context-menu
                          [bot]="selectedBot"
                          (edit)="edit()"
                          (delete)="delete()"
                          (history)="showHistoryModal()"
                          (duplicate)="duplicate()"
                          (promptHints)="showPromptHintsModal()">
      <app-bot-info [botItem]="selectedBot"></app-bot-info>
    </app-bot-context-menu>
    <!-- <div class="search-button-container" *ngIf="!selectedSource">
      <button mat-raised-button color="primary" (click)="openSourceSearch()">
        Search Sources
      </button>
    </div> -->
  </div>
  <div class="column">
    <div class="scrollable">
      <ng-container *ngIf="!isBotEditing">

        <div class="bot-content__config"
             [ngClass]="{hidden: !shouldFiltersBeVisible, 'absolute-position': showFiltersForCertainBot}">
          <form class="form" [formGroup]="botConfigForm">
            <div class="form-group w-full flex-wrap justify-content-end">
              <div class="form-field">
                <h4 class="m-t-0 mb-1">Language</h4>
                <div class="form-field-container">
                  <label for="language-select" class="form-field-label">Language</label>
                  <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
                    <mat-select id="language-select" formControlName="language"
                                placeholder="Select language">
                      <mat-option *ngFor="let language of languages" [value]="language">
                        {{ language.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="form-field"
                   *ngIf="['botCreator','quizBot'].includes(selectedBot.botType)">
                <h4 class="m-t-0 mb-1">Project</h4>
                <div class="form-field-container">
                  <label for="project-select" class="form-field-label">Project</label>
                  <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
                    <mat-select id="project-select" placeholder="Select projects"
                                formControlName="_id">
                      <mat-option
                                  *ngFor="let project of formProjects; let i = index"
                                  [value]="project._id">
                        {{ project.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="form-field"
                   *ngIf="['botCreator','quizBot'].includes(selectedBot.botType)">
                <h4 class="m-t-0 mb-1">Date</h4>
                <div class="form-field-container">
                  <label for="date-range" class="form-field-label">Date Range</label>
                  <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
                    <mat-date-range-input id="date-range" [rangePicker]="picker">
                      <input matStartDate formControlName="start" placeholder="Start date" />
                      <input matEndDate formControlName="end" placeholder="End date" />
                    </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                    <mat-error *ngIf="dateStart?.errors?.matStartDateInvalid">Invalid start date
                    </mat-error>
                    <mat-error *ngIf="dateEnd?.errors?.matEndDateInvalid">Invalid end
                      date</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="form-field"
                   *ngIf="['botCreator','quizBot'].includes(selectedBot.botType)">
                <h4 class="m-t-0 mb-1">Country</h4>
                <div class="form-field-container">
                  <label for="country-select" class="form-field-label">Country</label>
                  <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
                    <mat-select
                                id="country-select"
                                (focus)="resetCountrySearch()"
                                placeholder="Select country"
                                formControlName="countries"
                                multiple>
                      <div class="form-field-container">
                        <mat-form-field class="gingr-input-wrapper w-full country-search"
                                        appearance="outline">
                          <input
                                 id="country-search-input"
                                 matInput
                                 placeholder="Search"
                                 type="text"
                                 [formControl]="countrySearchTextInput" />
                        </mat-form-field>
                      </div>
                      <ng-container *ngFor="let value of getCountriesAndRegions()">
                        <div
                             [ngClass]="{
                        hidden: !value.name
                          .toLowerCase()
                          .includes(countrySearchTextInput.value?.toLowerCase() ?? '')
                      }">
                          <mat-option [value]="value._id">
                            {{ value.name }}
                          </mat-option>
                        </div>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </form>
        </div>

        <app-conversation-bot
                              *ngIf="selectedBot.botType === 'botCreator'"
                              [bot]="selectedBot"
                              [selectedProjectId]="selectedProjectId?.value"
                              [selectedCountries]="selectedCountries?.value"
                              [selectedDateStart]="dateStart?.value"
                              [selectedDateEnd]="dateEnd?.value"
                              (openFilters)="filterAreOpened = $event">
        </app-conversation-bot>
        <app-compare-bot
                         *ngIf="selectedBot.botType === 'compareBot'"
                         [bot]="selectedBot"></app-compare-bot>
        <app-summary-bot
                         *ngIf="selectedBot.botType === 'summaryBot'"
                         [bot]="selectedBot"></app-summary-bot>
        <app-quiz-bot
                      (openFilters)="filterAreOpened = $event"
                      *ngIf="selectedBot.botType === 'quizBot'"
                      [bot]="selectedBot"></app-quiz-bot>
      </ng-container>
      <ng-container *ngIf="isBotEditing">
        <div class="bot-actions">
          <button mat-raised-button color="primary" (click)="closeBotEdit()">
            <mat-icon>keyboard_arrow_left</mat-icon>
            Back
          </button>
        </div>
        <app-bot-editor [bot]="selectedBot" (cancelCallback)="closeBotEdit()"></app-bot-editor>
      </ng-container>
    </div>
  </div>
</div>