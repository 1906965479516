import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '@env/environment';
import { StringUtils } from '@shared/utils/stringUtils';

@Component({
  selector: 'app-branding',
  templateUrl: './branding.component.html',
  styles: [
    `
      .brand-logo {
        max-height: 60px;
        object-fit: contain;
      }
    `,
  ],
})
export class BrandingComponent implements OnInit, OnDestroy {
  constructor(@Inject(DOCUMENT) private document: Document) {}
  
  protected brandLogoPath = StringUtils.brandResource('logo');
  protected brandLogo: string | undefined;
  protected brandLogoPathLight = StringUtils.brandResource('logoLight');
  protected brandLogoWidth = environment.logoWidthPx ?? '160px';
  private observer: MutationObserver | undefined;

  get isDarkMode(): boolean {
    return this.document.documentElement.classList.contains('theme-dark');
  }

  ngOnInit(): void {
    this.updateLogo();
    
    // Watch for changes to the HTML element's class attribute
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'class') {
          this.updateLogo();
        }
      });
    });

    this.observer.observe(this.document.documentElement, {
      attributes: true,
      attributeFilter: ['class']
    });
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private updateLogo(): void {
    this.brandLogo = this.isDarkMode ? this.brandLogoPath : this.brandLogoPathLight;
  }

  // get brandLogo(): string {
  //
  // }


}
