import { Injectable } from '@angular/core';
import { INoticeDriver } from './notice-driver.interface';
import { CommonService } from '@shared/services/common.service';

@Injectable({
  providedIn: 'root',
})
export class TakedaNoticeDriver implements INoticeDriver {
  constructor(private commonService: CommonService) {}

  showNoticeAfterLogin(): void {
    this.commonService.openWarningSnackBar(
      'MEST-GPT.AI is exclusively for the Takeda Medical Affairs Team. If you belong to any commercial team then this access is due to an error – please inform Subir Roy.',
      60000
    );
  }
}
