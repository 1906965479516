import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SourcesApi } from '@core/api/sources/api';
import { IGNORE_ERRORS_KEY } from '@core';
import { Injectable } from '@angular/core';
import { IPaginatedVeevaResponse } from '@core/api/sources/types';
import { BehaviorSubject, Subject } from 'rxjs';
import { MetadataService, MetadataSlugs } from '@shared/services/metadata.service';

@Injectable({
  providedIn: 'root',
})
export class VeevaService {
  private _results$ = new Subject<IPaginatedVeevaResponse>();
  private _settings$ = new BehaviorSubject<{
    username: string;
    password: string;
    url: string;
  } | null>(null);

  constructor(
    private _http: HttpClient,
    private _metadataService: MetadataService
  ) {}

  get results$() {
    return this._results$.asObservable();
  }

  get settings$() {
    return this._settings$.asObservable();
  }

  fetchData(query: { [key: string]: string | number }) {
    const { url } = SourcesApi.fetchVeevaData(query);
    const headers = new HttpHeaders().set(IGNORE_ERRORS_KEY, 'true');
    this._http.get<IPaginatedVeevaResponse>(url, { headers }).subscribe(res => {
      this._results$.next(res);
    });
  }

  fetchFacets() {
    const { url } = SourcesApi.fetchVeevaFacets();
    const headers = new HttpHeaders().set(IGNORE_ERRORS_KEY, 'true');
    return this._http.get<{ statusValues: string[] }>(url, { headers });
  }

  fetchTypeFacets() {
    const { url } = SourcesApi.fetchVeevaFacetsTypes();
    const headers = new HttpHeaders().set(IGNORE_ERRORS_KEY, 'true');
    return this._http.get<{ typeValues: string[] }>(url, { headers });
  }

  importAllByQuery(folderId: string, query: { [key: string]: string }) {
    const headers = new HttpHeaders().set(IGNORE_ERRORS_KEY, 'true');
    const { url, body } = SourcesApi.importVeevaDataByQuery(folderId, query);
    return this._http.post(url, body, { headers });
  }

  importSelectedItems(folderId: string, documentsIds: number[]) {
    const headers = new HttpHeaders().set(IGNORE_ERRORS_KEY, 'true');
    const { url, body } = SourcesApi.importVeevaData({
      folderId,
      documentsIds,
    });
    return this._http.post(url, body, { headers });
  }

  saveSettings(settings: any, callback: () => void) {
    this._metadataService.set(MetadataSlugs.VeevaSettings, JSON.stringify(settings)).subscribe({
      next: () => {
        callback();
      },
    });
  }

  loadSettings() {
    if (this._settings$.value === null) {
      this._metadataService.get<any>(MetadataSlugs.VeevaSettings).subscribe(settings => {
        this._settings$.next(JSON.parse(settings.value));
      });
    }
  }
}
