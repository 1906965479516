<ng-container *ngIf="botItem!.type === 'botCreator'">
  <h3 class="mat-h3 m-t-24">Talking avatar configurations</h3>
  <form [formGroup]="avatarConfigForm">
    <p class="custom-label d-flex align-items-center">
      <span class="m-r-24">Is talking avatar available</span>
      <mat-slide-toggle formControlName="isAvailable"></mat-slide-toggle>
    </p>
    <div *ngIf="avatarConfigForm.controls.isAvailable.value">
      <div class="row">
        <div class="col-12">
          <div class="form-field-container">
            <label for="avatar-id-input" class="form-field-label">Avatar Name</label>
            <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
              <input id="avatar-id-input" matInput placeholder="Avatar ID"
                     formControlName="avatarId" />
            </mat-form-field>
          </div>
        </div>

        <div class="col-12">
          <div class="form-field-container">
            <label for="voice-rate-slider" class="form-field-label">Voice Rate</label>
            <div class="form-input">
              <mat-slider id="voice-rate-slider" class="gingr-input-wrapper materials-input"
                          min="0.5" max="1.5" step="0.1" discrete>
                <input matInput matSliderThumb formControlName="voiceRate" />
              </mat-slider>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="form-field-container">
            <label for="voice-emotion-select" class="form-field-label">Voice Emotion</label>
            <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
              <mat-select id="voice-emotion-select" formControlName="voiceEmotion">
                <mat-option *ngFor="let emotion of voiceEmotions" [value]="emotion">{{ emotion
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>