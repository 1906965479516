import { RoutesRoutingModule } from './routes-routing.module';
import { ApiAccessSettingsComponent } from './settings/api-access-settings/api-access-settings.component';
import { AppSettingsComponent } from './settings/app-settings/app-settings.component';
import { BotsSettingsComponent } from './settings/bots-settings/bots-settings.component';
import { EditProfileComponent } from './settings/edit-profile/edit-profile.component';
import { UserModalComponent } from './settings/users/user-modal/user-modal.component';
import { UsersComponent } from './settings/users/users.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxFileDropModule } from 'ngx-file-drop';

import { SharedModule } from '@shared/shared.module';

import { BotEditorComponent } from './components/bot-editor/bot-editor.component';
import { BotInfoComponent } from './components/bot-info/bot-info.component';
import { CompareBotComponent } from './components/compare-bot/compare-bot.component';
import { MaterialFileInputComponent } from './components/controls/material-file-input/material-file-input.component';
import { DragDropComponent } from './components/drag-drop/drag-drop.component';
import { FileEditorComponent } from './components/file-editor/file-editor.component';
import { FolderSourcesListComponent } from './components/folder-sources-list/folder-sources-list.component';
import { ApisContentComponent } from './components/gingr-content/apis-content/apis-content.component';
import { BotContextMenuComponent } from './components/gingr-content/bots-content/bot-context-menu/bot-context-menu.component';
import { BotsContentComponent } from './components/gingr-content/bots-content/bots-content.component';
import { FoldersContentComponent } from './components/gingr-content/folders-content/folders-content.component';
import { GingrContentComponent } from './components/gingr-content/gingr-content.component';
import { ProjectsContentComponent } from './components/gingr-content/projects-content/projects-content.component';
import { SourceContentModalComponent } from './components/gingr-content/source-content-modal/source-content-modal.component';
import { SourcesContentComponent } from './components/gingr-content/sources-content/sources-content.component';
import { SourcesMaterialsFormComponent } from './components/gingr-content/sources-materials/materials-form/sources-materials-form.component';
import { MaterialsListComponent } from './components/gingr-content/sources-materials/materials-list/materials-list.component';
import { NewBotModalComponent } from './components/gingr-new-items-modals/new-bot-modal/new-bot-modal.component';
import { NewConfigurationModalComponent } from './components/gingr-new-items-modals/new-configuration-modal/new-configuration-modal.component';
import { NewConversationModalComponent } from './components/gingr-new-items-modals/new-conversation-modal/new-conversation-modal.component';
import { NewFileModalComponent } from './components/gingr-new-items-modals/new-file-modal/new-file-modal.component';
import { NewFolderModalComponent } from './components/gingr-new-items-modals/new-folder-modal/new-folder-modal.component';
import { NewProjectModalComponent } from './components/gingr-new-items-modals/new-project-modal/new-project-modal.component';
import { NewProjectSourcesModalComponent } from './components/gingr-new-items-modals/new-project-sources-modal/new-project-sources-modal.component';
import { NewProjectsInstructionsModalComponent } from './components/gingr-new-items-modals/new-projects-instructions-modal/new-projects-instructions-modal.component';
import { NewSourceMetadataModalComponent } from './components/gingr-new-items-modals/new-source-metadata-modal/new-source-metadata-modal.component';
import { NewSourceModalComponent } from './components/gingr-new-items-modals/new-source-modal/new-source-modal.component';
import { InnerBotModalComponent } from './components/inner-bot-modal/inner-bot-modal.component';
import { InnerBotsComponent } from './components/inner-bots/inner-bots.component';
import { AllSearchResultsComponent } from './components/modals/all-search-results/all-search-results.component';
import { BotConfigComparisonComponent } from './components/modals/bot-config-history-modal/bot-config-comparison/bot-config-comparison.component';
import { BotConfigHistoryModalComponent } from './components/modals/bot-config-history-modal/bot-config-history-modal.component';
import { BotEditModalComponent } from './components/modals/bot-edit-modal/bot-edit-modal.component';
import { CompareSourcesModalComponent } from './components/modals/compare-sources-modal/compare-sources-modal.component';
import { ConfirmationModalComponent } from './components/modals/confirmation-modal/confirmation-modal.component';
import { DoubleOptionModalComponent } from './components/modals/double-option-modal/double-option-modal.component';
import { DuplicateBotModalComponent } from './components/modals/duplicate-bot-modal/duplicate-bot-modal.component';
import { EditProjectModalComponent } from './components/modals/edit-project-modal/edit-project-modal.component';
import { EditSourceFolderModalComponent } from './components/modals/edit-source-folder-modal/edit-source-folder-modal.component';
import { GeneralInstructionsModalComponent } from './components/modals/general-instructions-modal/general-instructions-modal.component';
import { ListModalComponent } from './components/modals/list-modal/list-modal.component';
import { NewECRDataModalComponent } from './components/modals/new-ecr-data-modal/new-ecr-data-modal.component';
import { SelectItemsToExportModalComponent } from './components/modals/select-items-to-export-modal/select-items-to-export-modal.component';
import { SourceFolderModalComponent } from './components/modals/source-folder-modal/source-folder-modal.component';
import { TextFileExportModalComponent } from './components/modals/text-file-export-modal/text-file-export-modal.component';
import { TileOptionModalComponent } from './components/modals/tile-option-modal/tile-option-modal.component';
import { QuizBotComponent } from './components/quiz-bot/quiz-bot.component';
import { SummaryBotComponent } from './components/summary-bot/summary-bot.component';
import { CountriesAllowedComponent } from './settings/app-settings/components/countries-allowed/countries-allowed.component';
import { DashboardContentComponent } from './settings/app-settings/components/dashboard-content/dashboard-content.component';
import { GlobalInstructionsComponent } from './settings/app-settings/components/global-instructions/global-instructions.component';
import { GlobalVocabularyRulesComponent } from './settings/app-settings/components/global-vocabulary-rules/global-vocabulary-rules.component';
import { VersionComponent } from './settings/app-settings/components/version/version.component';

import { ApisComponent } from './apis/apis.component';
import { BotsComponent } from './bots/bots.component';
import { ConversationBotComponent } from './bots/conversation-bot/conversation-bot.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProjectBotsComponent } from './projects/project-bots/project-bots.component';
import { ProjectSourcesComponent } from './projects/project-sources/project-sources.component';
import { ProjectUsersComponent } from './projects/project-user/project-users.component';
import { ProjectsComponent } from './projects/projects.component';
import { Error403Component } from './sessions/403.component';
import { Error404Component } from './sessions/404.component';
import { Error500Component } from './sessions/500.component';
import { ForgotPasswordComponent } from './sessions/forgot-password/forgot-password.component';
import { LoginComponent } from './sessions/login/login.component';
import { RegisterComponent } from './sessions/register/register.component';
import { MaterialsFoldersComponent } from './sources/materials/materials-folders.component';
import { SourcesComponent } from './sources/sources.component';
import { TwoFaToggleComponent } from './settings/users/two-fa-toggle/two-fa-toggle.component';
import { ToggleUserTwoFactorAuthComponent } from './components/two-factor-auth/toggle-user-two-factor-auth/toggle-user-two-factor-auth.component';

import { PromptHintsComponent } from './bots/prompt-hints/prompt-hints.component';
import { PromptHintsModalComponent } from './components/modals/propmt-hints-modal/prompt-hints-modal.component';
import { AutosizeModule } from 'ngx-autosize';
import { ImportSourceSelectorModalComponent } from './components/modals/import-source-selector/import-source-selector-modal.component';
import { NewConfluenceModalComponent } from './components/gingr-new-items-modals/new-confluence-modal/new-confluence-folder-modal.component';
import { TalkingAvatarSettingsComponent } from './components/bot-editor/talking-avatar-settings/talking-avatar-settings.component';
import { BotSelectorComponent } from './dashboard/bot-selector/bot-selector.component';
import { SourcesSettingsComponent } from './components/gingr-content/sources-settings/sources-settings.component';
import { SourceReferencesComponent } from './components/gingr-content/sources-settings/source-references/source-references.component';
import { SourceReferencesSearchComponent } from './components/gingr-content/sources-settings/source-references-search/source-references-search.component';
import { SourceEmbeddingsComponent } from './components/gingr-content/sources-settings/source-embedings/source-embeddings.component';
import { EditReferencesModalComponent } from './components/gingr-content/sources-settings/source-embedings/edit-references-modal/edit-references-modal.component';
import { ExternalReferenceFormModalComponent } from './components/gingr-content/sources-settings/source-embedings/external-reference-form-modal/external-reference-form-modal.component';
import { ResultItemComponent } from './results/result-item/result-item.component';
import { ResultsComponent } from './results/results.component';
import { ResultsListComponent } from './results/results-list/results-list.component';
import { NewVeevaModalComponent } from './components/gingr-new-items-modals/new-veeva-modal/new-veeva-modal.component';
import { SSOHandlerComponent } from './sessions/sso-handler/sso-handler.component';
import { SsoComponent } from './sessions/login/sso/sso.component';

@NgModule({
  imports: [
    SharedModule,
    RoutesRoutingModule,
    NgxFileDropModule,
    DragDropModule,
    MatTooltipModule,
    AngularEditorModule,
    ToggleUserTwoFactorAuthComponent,
    AutosizeModule,
  ],
  declarations: [
    DashboardComponent,
    LoginComponent,
    RegisterComponent,
    Error403Component,
    Error404Component,
    Error500Component,
    ForgotPasswordComponent,
    ResultsComponent,
    ProjectsComponent,
    SourcesComponent,
    BotsComponent,
    GingrContentComponent,
    BotsContentComponent,
    ProjectsContentComponent,
    SourcesContentComponent,
    FoldersContentComponent,
    FolderSourcesListComponent,
    NewConversationModalComponent,
    NewBotModalComponent,
    NewProjectModalComponent,
    NewConfluenceModalComponent,
    NewVeevaModalComponent,
    NewSourceModalComponent,
    ApisComponent,
    ApisContentComponent,
    BotInfoComponent,
    SourceFolderModalComponent,
    SelectItemsToExportModalComponent,
    UserModalComponent,
    FileEditorComponent,
    NewConfigurationModalComponent,
    NewProjectSourcesModalComponent,
    NewProjectsInstructionsModalComponent,
    NewSourceMetadataModalComponent,
    NewFileModalComponent,
    FolderSourcesListComponent,
    FoldersContentComponent,
    NewFolderModalComponent,
    SourceContentModalComponent,
    ConfirmationModalComponent,
    EditProfileComponent,
    BotEditModalComponent,
    ProjectSourcesComponent,
    ListModalComponent,
    ProjectBotsComponent,
    EditProjectModalComponent,
    NewECRDataModalComponent,
    ApiAccessSettingsComponent,
    DoubleOptionModalComponent,
    ImportSourceSelectorModalComponent,
    TileOptionModalComponent,
    DragDropComponent,
    BotEditorComponent,
    InnerBotsComponent,
    InnerBotModalComponent,
    EditSourceFolderModalComponent,
    BotsSettingsComponent,
    SummaryBotComponent,
    QuizBotComponent,
    ResultItemComponent,
    CompareSourcesModalComponent,
    CompareBotComponent,
    AllSearchResultsComponent,
    ConversationBotComponent,
    AppSettingsComponent,
    GlobalVocabularyRulesComponent,
    CountriesAllowedComponent,
    GlobalInstructionsComponent,
    GeneralInstructionsModalComponent,
    TextFileExportModalComponent,
    MaterialsFoldersComponent,
    SourcesMaterialsFormComponent,
    MaterialFileInputComponent,
    MaterialsListComponent,
    UsersComponent,
    DashboardContentComponent,
    BotContextMenuComponent,
    DuplicateBotModalComponent,
    BotConfigHistoryModalComponent,
    ProjectUsersComponent,
    BotConfigComparisonComponent,
    VersionComponent,
    TwoFaToggleComponent,
    PromptHintsComponent,
    PromptHintsModalComponent,
    TalkingAvatarSettingsComponent,
    BotSelectorComponent,
    SourcesSettingsComponent,
    SourceReferencesComponent,
    SourceReferencesSearchComponent,
    SourceEmbeddingsComponent,
    EditReferencesModalComponent,
    ExternalReferenceFormModalComponent,
    ResultsListComponent,
    SSOHandlerComponent,
    SsoComponent,
  ],
})
export class RoutesModule {}
