<div class="sso">
  <div class="divider">Or log in with</div>
  <div class="drivers">
    <div>
      <div *ngIf="!waitingForSSO" class="buttons">
        <img (click)="loginViaSSO('microsoft')" src="assets/sso/ms.svg" alt="Microsoft"/>
        <img (click)="loginViaSSO('octa')" src="assets/sso/octa.png" alt="Octa"/>
      </div>
      <app-spinner [message]="null" [small]="true" *ngIf="waitingForSSO"></app-spinner>
    </div>
  </div>
</div>
