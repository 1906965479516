import { Component, Input } from '@angular/core';
import { TAdaptedMessage } from '@shared/services/bots/types/types';
import { QuizAnswer, QuizQuestion } from '@core/interfaces/TBot';
import { BotsService } from '@shared/services/bots.service';
import { ArrayUtils } from '@shared/utils/arrayUtils';
import { TSourceDetail } from '@core/interfaces/TMessage';

@Component({
  selector: 'app-bot-quiz-answers',
  templateUrl: './bot-quiz-answers.component.html',
  styleUrls: ['./bot-quiz-answers.component.scss'],
})
export class BotQuizAnswersComponent {
  protected questions: QuizQuestion[] = [];
  protected currentQuestionIndex = 1;
  protected questionQty = 0;
  protected sourcesDetails: TSourceDetail[] = [];

  @Input() set message(value: TAdaptedMessage) {
    if (value.paragraphs[0]?.quizQuestions && value.paragraphs[0].quizQuestions.length > 0) {
      this.questions = this.shuffleQuiz(value.paragraphs[0].quizQuestions);
      this.questionQty = value.paragraphs[0].quizQuestions.length;
      this.addAssistantQuestion(value.paragraphs[0].quizQuestions[0].question);
      this.sourcesDetails = value.sources;
    } else {
      this.questionQty = 0;
      this.currentQuestionIndex = 0;
      return;
    }
    this.currentQuestionIndex = 1;
  }

  @Input() asResults = false;
  @Input() conversationId: string | null = null;

  constructor(private _botService: BotsService) {}

  checkAnswer(question: QuizQuestion, answer: QuizAnswer, questionIndex: number): void {
    question.disabled = true;
    answer.selected = true;
    question.correctAnswered = answer.correct === answer.selected;
    if (this.currentQuestionIndex < this.questionQty) {
      this.currentQuestionIndex++;
    }
    this.sendMessages(question, answer, questionIndex);
  }

  getCorrectAnswerForQuestion(question: QuizQuestion): string {
    const answer = question.answers.find(answer => answer.correct);
    return answer?.answer || '';
  }

  private shuffleQuiz(quiz: QuizQuestion[]): QuizQuestion[] {
    return quiz.map((question: QuizQuestion) => {
      return {
        ...question,
        answers: ArrayUtils.shuffle(question.answers),
      };
    });
  }

  private addAssistantQuestion(message: string): void {
    if (this.conversationId) {
      this._botService.addAssistantQuestion(
        this.conversationId,
        JSON.stringify({ type: 'assistant', data: message })
      );
    }
  }

  private sendMessages(question: QuizQuestion, answer: QuizAnswer, questionIndex: number): void {
    const dataForResults = {
      type: 'quiz',
      data: {
        question: question.question,
        answers: question.answers,
      },
    };

    const assistantMessage = {
      type: 'assistant',
      data: this.generateAssistanceMessage(question, answer),
    };

    const userMessage = {
      type: 'user',
      data: answer.answer,
    };

    if (this.conversationId) {
      this._botService.sendAnswerAndNextQuestion({
        conversationId: this.conversationId,
        userAnswer: JSON.stringify(userMessage),
        correct: answer.correct,
        assistantMessage: JSON.stringify(assistantMessage),
        // nextQuestion: this.questions[questionIndex + 1]?.question,
        dataForResultsStore: JSON.stringify(dataForResults),
      });
    }
  }


  protected getAnswerClass(question: QuizQuestion, answer: QuizAnswer) {
    if (this.asResults) {
      return {
        correct: answer.correct,
        incorrect: answer.selected && !answer.correct,
      };
    } else {
      return {
        correct: question.disabled && answer.correct,
        incorrect: answer.selected && !answer.correct,
      };
    }
  }

  private generateAssistanceMessage(question: QuizQuestion, answer: QuizAnswer): string {
    if (answer.correct) {
      return "Great that's correct answer!";
    }
    return `Sorry, that's incorrect answer! Correct answer is ${this.getCorrectAnswerForQuestion(question)}.`;
  }
}
