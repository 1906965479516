import { TSource } from '@core/interfaces/Endpoints';
import { IPaginationDto } from '../../../features/api/dtos/pagination.dto';

export type SourceUpdateBody = Partial<TSource>;

export type TConfluencePaging = {
  limit: number;
  size: number;
  start: number;
  _links?: {
    next: string;
    prev: string;
  };
};

export const INITIAL_CONFLUENCE_PAGING: TConfluencePaging = {
  limit: 20,
  size: 20,
  start: 0,
};

export type TConfluencePaginated<T extends TConfluenceSpace | TConfluencePage> = {
  results: T[];
} & TConfluencePaging;

export type TConfluenceSpace = {
  name: string;
  key: string;
};

export type TConfluencePage = {
  id: string;
  status: string;
  title: string;
  space?: string;
  spaceKey?: string;
};

export type TConfluenceImport = {
  folderName: string;
  folderStatus: string;
  pagesIds: string[];
  spacesIds: string[];
};

export interface TExternalReference {
  referenceText: string;
  referenceLink: string;
  _id?: string;
}

export interface TSourceEmbedding {
  externalReferences: any[];
  _id: string;
  content: string;
  source: string[];
  priority: number;
  status: string;
  publicationDate: Date | null;
  countries: any[];
  __v: number;
  expirationDate?: Date;
  publicationYear?: number;
  publicationMonth?: number;
  dateRangeStart?: Date;
  dateRangeEnd?: Date;
  keywords?: string[];
  summary?: string;
}

export interface IPaginatedVeevaResponse {
  data: IVeevaItem[];
  pagination: IPaginationDto;
}

export interface IVeevaItem {
  id: number;
  name: string;
  status: string;
  title: string;
  createdAt: string;
  uiIsSelected?: boolean;
}

export interface TVeevaImportIdsDto {
  folderId: string;
  documentsIds: number[];
}

export interface TVeevaImportQueryDto {
  folderId: string;

  [key: string]: string;
}
