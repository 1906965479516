import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicStatusService } from './dynamic-status.service';
import { Subscription } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-dynamic-status',
  templateUrl: './dynamic-status.component.html',
  styleUrls: ['./dynamic-status.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state(
        'void',
        style({
          opacity: 0,
        })
      ),
      transition('void => *', [animate('400ms ease-out')]),
      transition('* => void', [
        animate(
          '400ms ease-out',
          style({
            opacity: 0,
            transform: 'translateY(-10px)',
          })
        ),
      ]),
    ]),
  ],
})
export class DynamicStatusComponent implements OnInit, OnDestroy {
  constructor(private service: DynamicStatusService) {}

  protected currentStatus = '';
  private sub: Subscription[] = [];

  ngOnInit(): void {
    this.sub.push(
      this.service.statuses.subscribe(status => {
        this.currentStatus = status;
      })
    );

    this.sub.push(
      this.service.clearStatuses.subscribe(() => {
        this.currentStatus = '';
      })
    );
  }

  ngOnDestroy(): void {
    this.sub.forEach(s => s.unsubscribe());
  }
}
