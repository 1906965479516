import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { CommonService } from '@shared/services/common.service';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit, OnDestroy {
  @Output() public actionCallback = new EventEmitter();

  private _subs: Subscription[] = [];
  isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { text: string },
    private dialogRef: MatDialogRef<ConfirmationModalComponent>,
    private _commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.isLoading = false;
    this._subs.push(
      this._commonService.getIsLoadingObservable().subscribe(results => {
        this.isLoading = results;
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }

  cancel() {
    this.dialogRef.close();
  }

  confirm() {
    this.isLoading = true;
    this.actionCallback.emit();
  }
}
