import { GetRequestData } from '@core/api/utils';
import { environment } from '@env/environment';

export class AuthApi {
  static microsoftRedirection(): GetRequestData {
    return {
      url: `${environment.backendURL}/api/auth/ms-auth-url`,
    };
  }
  static octaRedirection(): GetRequestData {
    return {
      url: `${environment.backendURL}/api/auth/okta-auth-url`,
    };
  }
}
