import { Component, OnDestroy, OnInit } from '@angular/core';
import { UsersService } from '@shared/services/users.service';
import { TBaseUser } from '@core/interfaces/Endpoints';
import { Subscription } from 'rxjs';
import { INITIAL_PAGING, TPaging } from '@core/interfaces/TPaging';
import { CommonService } from '@shared/services/common.service';
import { PageEvent } from '@angular/material/paginator';

import { MatDialog } from '@angular/material/dialog';
import { UserModalComponent } from './user-modal/user-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationModalComponent } from '../../components/modals/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, OnDestroy {
  protected users: TBaseUser[] = [];
  private _subs: Subscription[] = [];
  protected paging: TPaging = INITIAL_PAGING;

  constructor(
    private _usersService: UsersService,
    private _commonService: CommonService,
    private _dialog: MatDialog,
    private _router: Router,
    private _activeRoute: ActivatedRoute,
  ) {
  }


  ngOnInit(): void {
    this._subs.push(this._usersService.usersPaging$.subscribe(paging => {
      this.paging = paging;
    }));

    this._subs.push(this._usersService.users$.subscribe(users => {
      this.users = users;
      this.navigateToUser(); //Opens modal if url contains username
    }));
    this.getUsers();
  }

  handlePageEvent(e: PageEvent) {
    this.paging.page = e.pageIndex + 1;
    this.getUsers();
  }

  getUsers(): void {
    this._usersService.getUsers(this.paging.page, this.paging.limit);
  }

  protected addUser() {
    this.processDialog();
  }

  protected editUser(user: TBaseUser) {
    this._router.navigate([], { queryParams: { username: user.username } }).then(() => {
      this.processDialog(user);
    });
  }

  protected processDialog(user: TBaseUser | null = null) {
    const dialogRef = this._dialog.open(UserModalComponent, {
      data: user,
    });

    const dialogSub = dialogRef.componentInstance.userChanged.subscribe(value => {
        if (value) {
          this.getUsers();
        }
        dialogSub.unsubscribe();
      },
    );
  }

  protected deleteUser(user: TBaseUser) {
    const dialogRef = this._dialog.open(ConfirmationModalComponent, {
      data: { text: `Are you sure you want to delete "${user.username}"?` },
    });
    const dialogSubscription = dialogRef.componentInstance.actionCallback.subscribe(() => {
      this._usersService.deleteUser(user).subscribe(() => {
        this.getUsers();
        dialogRef.close();
        this._commonService.openSuccessSnackBar(`User "${user.username}" was deleted`);
        dialogSubscription.unsubscribe();
      });
    });
  }

  protected navigateToUser() {
    const username = this._activeRoute.snapshot.queryParams.username;
    const foundUser = username && this.users.find(user => user.username === username);

    foundUser && this.editUser(foundUser);
  }

  disable2Fa(user: TBaseUser) {
    const dialogRef = this._dialog.open(ConfirmationModalComponent, {
      data: { text: `Are you sure you want to disable 2FA for user "${user.username}"?` },
    });
    const dialogSubscription = dialogRef.componentInstance.actionCallback.subscribe(() => {
      this._usersService.toggle2Fa(user.username, false).subscribe({
        error: () => this._commonService.openErrorSnackBar(`Failed to disable 2FA for user "${user.username}"`),
        next: () => {
          user.is2FAEnabled = false;
          this._commonService.openSuccessSnackBar(`2FA disabled for user "${user.username}"`)
        },
      });
      dialogSubscription.unsubscribe();
      dialogRef.close();
    });
  }

  ngOnDestroy(): void {
    this._subs.forEach(value => value.unsubscribe());
  }
}
