<ng-container>
  <app-bot-results [bot]="bot"></app-bot-results>
  <app-dynamic-status></app-dynamic-status>
</ng-container>

<div *ngIf="conversationId && !isLoading">

  <button
    *ngIf="maxQuestions > 0"
    class="m-b-24 w-full"
    mat-raised-button
    color="accent"
    [disabled]="isGettingFeedback "
    (click)="getMoreQuestions()"
  >
    More questions
  </button>
  <button
    *ngIf="isFeedbackAllowed"
    class="m-b-24 w-full"
    mat-raised-button
    color="accent"
    [disabled]="isGettingFeedback"
    (click)="getFeedback()"
  >
    Get feedback
  </button>
</div>

