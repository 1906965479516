import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../../../components/modals/confirmation-modal/confirmation-modal.component';
import { CommonService } from '@shared/services/common.service';
import { AppSettingsService } from '@shared/services/settings/app-settings.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-two-fa-toggle',
  templateUrl: './two-fa-toggle.component.html',
  styleUrls: ['./two-fa-toggle.component.scss'],
})
export class TwoFaToggleComponent implements AfterViewInit, OnDestroy {

  @ViewChild('toggleSlide') slide: MatSlideToggle | undefined;
  private isRequired = false;
  private sub: Subscription | undefined;

  constructor(
    private _dialog: MatDialog,
    private _commonService: CommonService,
    private _appSettingsService: AppSettingsService,
  ) {

  }

  ngAfterViewInit(): void {
    this.sub = this._appSettingsService.getSettingsAsObservable().subscribe(value => {
      this.isRequired = value.two_factor_auth_required as boolean;
      setTimeout(() => {
        this.setToggleValue();
      });
    });

  }

  setToggleValue() {
    this.slide!.checked = this.isRequired;
  }

  protected toggle(event: MatSlideToggleChange) {
    this.setToggleValue();
    const dialogRef = this._dialog.open(ConfirmationModalComponent, {
      data: {
        text: this.getDialogMessage(),
      },
    });
    const dialogSub = dialogRef.componentInstance.actionCallback.subscribe(() => {
      this._appSettingsService.storeSettingMetadata('two_factor_auth_required', event.checked, () => {
        this.isRequired = event.checked;
        this.setToggleValue();
        this._commonService.openSuccessSnackBar('2FA changed successfully');
        dialogRef.close();
        dialogSub.unsubscribe();
      });


    });
  }

  private getDialogMessage(): string {
    let text = `Are you sure you want to ${this.isRequired ? 'disable' : 'enable'} two factor authorization?`;
    if (!this.isRequired) {
      text += '<br>All users will be requested to setup an authorization application (like Google Authenticator) in their next login in.';
    } else {
      text += '<br>The 2FA will not be requested for user that didn\'t enabled it.';
    }
    return text;
  }

  ngOnDestroy() {
    this.sub?.unsubscribe()
  }
}
