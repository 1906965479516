<div class="modal">
  <form [formGroup]="userForm" (ngSubmit)="save()">

    <h2>{{ isEditMode ? 'Edit: ' + data!.username : 'Create user' }}</h2>
    <app-spinner *ngIf="isInitializing" message=""></app-spinner>
    <div class="modal--content" *ngIf="!isInitializing">
      <ng-container *ngIf="!isEditMode">
        <div class="form-field-container">
          <label for="username-input" class="form-field-label">Username <span
                  class="asterisk">*</span></label>
          <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
            <input id="username-input" matInput placeholder="Enter username" type="text"
                   formControlName="username" required />
          </mat-form-field>
        </div>
      </ng-container>

      <ng-container *ngIf="!isEditMode">
        <div class="form-field-container">
          <label for="password-input" class="form-field-label">Password <span
                  class="asterisk">*</span></label>
          <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
            <input id="password-input" matInput placeholder="Min. 5 characters" type="Password"
                   formControlName="password" required />
          </mat-form-field>
        </div>
      </ng-container>

      <div class="form-field-container">
        <label class="form-field-label">Roles <span class="asterisk">*</span></label>
        <div formGroupName="roles">
          <div *ngFor="let role of roles">
            <mat-checkbox [formControlName]="role" class="example-margin">
              {{ role }}
            </mat-checkbox>
          </div>
        </div>
      </div>

    </div>
    <div class="d-flex align-items-center m-t-16 justify-content-between">

      <button
              mat-raised-button
              color="accent"
              type="reset"
              (click)="cancel()">
        Close
      </button>

      <div>
        <button
                [disabled]="!userForm.valid"
                mat-raised-button
                color="accent"
                type="submit">
          Save
        </button>

      </div>
    </div>
  </form>
</div>