<div class="new-item-modal">
    <h3 class="mat-h3">Add new project</h3>
    <form class="form-field-full" [formGroup]="newProjectForm">
        <div class="form-inputs">
            <div class="form-field-container">
                <label for="project-name-input" class="form-field-label">Source name</label>
                <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
                    <input
                           id="project-name-input"
                           matInput
                           placeholder="Enter source name"
                           type="text"
                           formControlName="name"
                           required />
                    <mat-error *ngIf="name.invalid">
                        <span *ngIf="name.errors?.required">Please enter source name
                        </span>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <button
                class=""
                mat-raised-button
                color="accent"
                [disabled]="newProjectForm.invalid"
                (click)="addNewItem()">
            Add new project
        </button>
    </form>
</div>