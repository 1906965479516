<div class="veeva-modal">
  <h3 class="mat-h3">Add new sources from Veeva</h3>
  <mat-stepper [linear]="true" #stepper>
    <mat-step label="Search" [stepControl]="searchForm">
      <form class="form-field-full" [formGroup]="searchForm" (ngSubmit)="onSearch()">

        <div class="searching-container">
          <mat-form-field class="gingr-input-wrapper w-full hide-sub">
            <mat-select formControlName="facets" placeholder="Select facet">
              <mat-option *ngFor="let facet of facets" [value]="facet">
                {{ facet }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="searchForm.get('facets')?.invalid">
              <span *ngIf="searchForm.get('facets')?.errors?.required">Please select a facet</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="gingr-input-wrapper w-full hide-sub">
            <mat-select formControlName="facetsType" placeholder="Select facet type">
              <mat-option *ngFor="let facetType of facetsTypes" [value]="facetType">
                {{ facetType }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="searchForm.get('facetType')?.invalid">
              <span *ngIf="searchForm.get('facetType')?.errors?.required">Please select a facet type</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="gingr-input-wrapper hide-sub">
            <input
              matInput
              placeholder="Text"
              type="text"
              formControlName="text"
              required
            />
            <mat-error *ngIf="searchForm.get('text')?.invalid">
              <span *ngIf="searchForm.get('text')?.errors?.required">Please enter text</span>
            </mat-error>
          </mat-form-field>
          <div class="d-flex">
            <app-spinner [message]="null" [small]="true"
                         *ngIf="(isLoading || searchForm.disabled); else button"></app-spinner>
            <ng-template #button>
              <div class="d-flex justify-content-end">
                <button
                  mat-raised-button
                  color="accent"
                  mat-button
                  [disabled]="searchForm.invalid"
                >
                  Search
                </button>
              </div>
            </ng-template>
          </div>
        </div>
      </form>
      <div class="progress-bar-container">
        <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
      </div>
      <div *ngIf="tryAgainButton" class="d-flex justify-content-center">
        <button
          (click)="getFacets()"
          mat-raised-button
          color="accent"
          mat-button
        >Try again
        </button>
      </div>
      <mat-checkbox *ngIf="results.length" [(ngModel)]="importAllByQuery" (change)="onImportAllByQueryChange($event)">
        Import all by query parameters
      </mat-checkbox>
      <div class="found-results-container">
        <div class="results-container">
          <h3 *ngIf="results.length">{{ pagination?.total ?? 0 }} items found</h3>
          <div class="results">
            <div *ngFor="let item of results; let i = index" class="result">
              <mat-checkbox [disabled]="importAllByQuery || isLoading" [(ngModel)]="selectedResults[item.id]"
                            [ngModelOptions]="{standalone: true}"
                            (change)="onCheckboxChange($event, item)">
                {{ item.name }}
              </mat-checkbox>
            </div>
            <p *ngIf="results.length" (click)="onSearch()">Load more</p>
          </div>
        </div>
        <div class="selected-items-container">
          <h3 *ngIf="selectedResultsAreValid()">Selected items ({{ Object.values(selectedResults).length }})</h3>
          <div *ngFor="let item of Object.values(selectedResults)" class="selected-item">
            <mat-icon (click)="removeSelectedItem(item.id)">close</mat-icon>
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div class="d-flex justify-content-end full-width">
          <button
            [disabled]="!canProceedImport()"
            *ngIf="results.length"
            mat-raised-button
            color="accent"
            mat-button
            matStepperNext
          >Next
          </button>
        </div>

      </div>

    </mat-step>
    <mat-step label="Create Folder">
      <form class="form-field-full" [formGroup]="folderForm">

        <div class="form-inputs">
          <mat-form-field class="gingr-input-wrapper w-full">
            <input matInput placeholder="Source name" type="text" formControlName="name" required/>
            <mat-error *ngIf="folderForm.get('name')?.errors?.invalid">
              <span *ngIf="folderForm.errors?.required">Please enter source name </span>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="gingr-input-wrapper w-full">
            <mat-select formControlName="status" placeholder="Select status">
              <mat-option *ngFor="let st of statuses" [value]="st">
                {{ st | titlecase }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="folderForm.get('status')?.errors?.invalid">
              <span *ngIf="folderForm.errors?.required">Please provide status </span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="d-flex justify-content-end">
          <button
            [disabled]="!isSubmitValid()"
            mat-raised-button
            color="accent"
            mat-button
            matStepperNext
          >Import
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step label="Import" [editable]="false">
      <div class="d-flex align-items-center justify-content-center full-width full-height">
        <div *ngIf="canProceedImport(); else notReady">
          <!--          <ng-template #done>-->
          <div class="info" *ngIf="!importError && !isLoading && !importStarted">
            <mat-icon class="success">done_all</mat-icon>
            <span>Ready to import</span>
          </div>
          <div class="info" *ngIf="!importError && !isLoading && importStarted">
            <mat-icon class="success">task_alt</mat-icon>
            <span>The import started. It will take a while (depending on the number of articles) to process the import.</span>
          </div>
          <app-spinner [message]="null" [small]="true" *ngIf="isLoading"></app-spinner>
          <div class="info" *ngIf="importError && !isLoading">
            <mat-icon class="error">error</mat-icon>
            <span>Import failed</span>
          </div>
          <!--          </ng-template>-->
        </div>
        <ng-template #notReady>
          <div class="info">
            <mat-icon class="error">error</mat-icon>
            <span>Can not start import - fill all fields to proceed</span>
          </div>
        </ng-template>

      </div>
      <div class="d-flex justify-content-end">
        <button
          *ngIf="!importStarted"
          (click)="onSubmit()"
          [disabled]="!canProceedImport()"
          mat-raised-button
          color="accent"
          mat-button
          matStepperNext
        >Import
        </button>
        <button
          *ngIf="importStarted"
          (click)="onClose()"
          mat-raised-button
          color="accent"
          mat-button
        >Close window
        </button>
      </div>
    </mat-step>
  </mat-stepper>
</div>
