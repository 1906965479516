import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, LoginService } from '@core';

@Component({
  selector: 'app-sso-handler',
  template: '',
})
export class SSOHandlerComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private loginService: LoginService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const { token, username } = this.retrieveParams(params);

      if (token && username) {
        this.authService.setToken(token, username);

        this.loginService.me(username).subscribe((user)=>{
          this.authService.setUser(user);
          this.router.navigateByUrl('/');
        });

      } else {
        this.router.navigateByUrl('/login');
      }
    });
  }

  private retrieveParams(params: any) {
    let token: string | undefined = undefined;
    try {
      token = JSON.parse(params.token).access_token;
    } catch (e) {}

    return {
      token,
      username: params.username,
    };
  }
}
