import { Injectable, OnDestroy } from '@angular/core';
import { ApiAbstractsService } from '../../api/servces/slr/api-abstracts.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { AbstractResponseDto, SLRAbstractDto } from '../../api/dtos/abstracts.dto';
import { INITIAL_PAGINATION_CONFIG } from '../../layout/components/organisms/table/table.types';
import { IPaginationDto } from '../../api/dtos/pagination.dto';
import { TQuery } from '../../api/types/general.types';
import { FilterableContract } from '../contracts/filterable.contract';
import { TFiltersDto } from 'app/features/api/dtos/filters.dto';
import { ProjectsService } from './projects.service';
import { QueryFiltersService } from './query-filters.service';

@Injectable({
  providedIn: 'root',
})
export class AbstractsService implements FilterableContract, OnDestroy {
  private _abstractList$ = new Subject<SLRAbstractDto[]>();
  private _isLoading$ = new BehaviorSubject<boolean>(false);
  private _pagination$ = new BehaviorSubject<IPaginationDto>(INITIAL_PAGINATION_CONFIG);
  private _subs: Subscription[] = [];

  constructor(
    private _apiAbstractService: ApiAbstractsService,
    private _projectService: ProjectsService,
    private _filtersQueryService: QueryFiltersService
  ) {}

  get pagination$(): Observable<IPaginationDto> {
    return this._pagination$.asObservable();
  }

  get abstractLists$(): Observable<SLRAbstractDto[]> {
    return this._abstractList$.asObservable();
  }

  get isLoading(): Observable<boolean> {
    return this._isLoading$.asObservable();
  }

  loadAbstracts(paging: TQuery = this._pagination$.value as TQuery): void {
    const query: TQuery = { ...paging, ...this._filtersQueryService.getCurrentFilters() };
    this._isLoading$.next(true);
    this._subs.push(
      this._apiAbstractService
        .getForProject(this._projectService.selectedProjectId!, query)
        .subscribe(response => {
          this._abstractList$.next(response.data);
          this._pagination$.next(response.pagination);
          this._isLoading$.next(false);
        })
    );
  }

  getByID(id: string): Observable<AbstractResponseDto> {
    return this._apiAbstractService.getByID(id);
  }

  createAbstract(abstract: SLRAbstractDto): Observable<SLRAbstractDto> {
    return this._apiAbstractService.create(abstract);
  }

  updateAbstract(abstract: SLRAbstractDto): Observable<SLRAbstractDto> {
    return this._apiAbstractService.update(abstract.abstractId, abstract);
  }

  getFilters(): Observable<TFiltersDto> {
    this._isLoading$.next(true);
    return this._apiAbstractService.fetchFilters(this._projectService.selectedProjectId!);
  }

  uploadFiles(file: File, abstractId: number): Observable<void> {
    return this._apiAbstractService.uploadFile(file, abstractId);
  }
  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }
}
