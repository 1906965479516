import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { ToastrModule } from 'ngx-toastr';

import { FormattedDatePipe } from '@shared/pipes/formatted-date.pipe';

import { SourcePreviewButtonComponent } from '../routes/components/modals/source-preview/button/source-preview-button.component';
import { SourcePreviewModalComponent } from '../routes/components/modals/source-preview/modal/source-preview-modal.component';
import { MaterialsPreviewComponent } from '../routes/components/sources/materials-preview/materials-preview.component';
import { FileDownloadComponent } from '../routes/components/sources/media-types/file-download/file-download.component';
import { ImagePreviewModalComponent } from '../routes/components/sources/media-types/image-viewer/image-preview-modal/image-preview-modal.component';
import { ImageViewerComponent } from '../routes/components/sources/media-types/image-viewer/image-viewer.component';
import { LinkComponent } from '../routes/components/sources/media-types/link/link.component';
import { VideoPlayerComponent } from '../routes/components/sources/media-types/video-player/video-player.component';
import { YoutubePlayerComponent } from '../routes/components/sources/media-types/youtube-player/youtube-player.component';
import { BadgeComponent } from './components/badge/badge.component';
import { BotSearchComponent } from './components/bot-search/bot-search.component';
import { BotStatusBadgeComponent } from './components/bot-status-badge/bot-status-badge.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ErrorCodeComponent } from './components/error-code/error-code.component';
import { ExportToSelectorComponent } from './components/export-to-selector/export-to-selector.component';
import { JobStatusComponent } from './components/job-status/job-status.component';
import { AddToListButtonComponent } from './components/multi-summary/add-to-list-button/add-to-list-button.component';
import { MultiSummaryComponent } from './components/multi-summary/multi-summary.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { QuizComponent } from './components/quiz/quiz.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TopSearchComponent } from './components/top-search/top-search.component';
import { ReferencesComponent } from '@shared/components/references/references.component';

import { MaterialModule } from '../material.module';
import { MaterialExtensionsModule } from '../material-extensions.module';
import { DisableControlDirective } from './directives/disable-control.directive';
import { TextEllipsisDirective } from './directives/text-ellipsis.directive';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { ToObservablePipe } from './pipes/to-observable.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { HasRoleDirective } from '@shared/directives/has-role.directive';
import { UsersSelectorComponent } from '@shared/components/users-selector/users-selector.component';
import { DateFromNowPipe } from './pipes/date-from-now.pipe';
import { FirstUpperPipe } from './pipes/first-upper.pipe';
import { SanitizeStrPipe } from './pipes/sanitize-str.pipe';
import { DragAndDropComponent } from '../routes/components/drag-and-drop/drag-and-drop.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { AppExpandableDirective } from './directives/app-expandable.directive';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { NoticeComponent } from './components/notice/notice.component';
import { NoSanitizePipe } from './pipes/no-sanitize.pipe';
import { StarButtonComponent } from './components/star-button/star-button.component';
import { SimplePaginatorComponent } from './components/simple-paginator/simple-paginator.component';
import { SourceFolderFormComponent } from './components/sources/source-folder-form/source-folder-form.component';
import { CounterBadgeComponent } from './components/counter-badge/counter-badge.component';
import { FilePreviewModalComponent } from './components/file-preview-modal/file-preview-modal.component';
import { PreviewModalComponent } from './components/file-preview-modal/preview-modal/preview-modal.component';
import { DocumentComponent } from './components/file-preview-modal/drivers/document/document.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { GoodPromptButtonComponent } from './components/good-prompt-button/good-prompt-button.component';
import { ShowOriginalResourcesComponent } from './components/show-original-resources/show-original-resources.component';
import { OriginalResourceModalComponent } from '@shared/components/show-original-resources/original-resource-modal/original-resource-modal.component';
import { BotResultsComponent } from '../routes/components/bots-messages/bot-results/bot-results.component';
import { SingleMessageComponent } from '../routes/components/bots-messages/bot-results/single-message/single-message.component';
import { MessageControlsComponent } from '../routes/components/bots-messages/message-controls/message-controls.component';
import { BotQuizAnswersComponent } from '../routes/components/bots-messages/bot-results/bot-quiz-answers/bot-quiz-answers.component';
import { QuizReferenceComponent } from '../routes/components/bots-messages/bot-results/bot-quiz-answers/quiz-reference/quiz-reference.component';
import { TalkingAvatarComponent } from '../routes/bots/talking-avatar/talking-avatar.component';
import { StreamPlayerComponent } from '../routes/components/sources/media-types/stream-player/stream-player.component';
import { MinRoleDirective } from '@shared/directives/min-role.directive';
import { TalkingAvatarPlaylistComponent } from '../routes/components/bots-messages/talking-avatar-playlist/talking-avatar-playlist.component';
import { ConversationInputComponent } from '../routes/bots/conversation-bot/conversation-input/conversation-input.component';
import { InputFilePreviewComponent } from '../routes/bots/conversation-bot/conversation-input/input-file-preview/input-file-preview.component';
import { AutosizeModule } from 'ngx-autosize';
import { ConversationDndComponent } from '../routes/bots/conversation-bot/conversation-input/conversation-dnd/conversation-dnd.component';
import { SpeechRecognitionComponent } from '../routes/bots/conversation-bot/conversation-input/speech-recognition/speech-recognition.component';
import { PromptHintsMenuComponent } from '../routes/bots/conversation-bot/prompt-hints-menu/prompt-hints-menu.component';
import { FeatureCheckDirective } from '@shared/directives/feature-check.directive';
import { OriginalContentComponent } from '../routes/components/bots-messages/bot-results/original-content/original-content.component';
import { SourceSearchModalComponent } from '../routes/components/modals/source-search-modal/source-search-modal.component';
import { LocalSourcesModule } from './components/local-sources/local-sources.module';
import { FileUploadService } from './services/file-upload.service';
import { SourceViewerSidebarComponent } from '../routes/components/bots-messages/bot-results/original-content/source-viewer-sidebar.component';
import { DynamicStatusComponent } from '../routes/bots/conversation-bot/dynamic-status/dynamic-status.component';
import { ImageFallbackDirective } from './directives/image-fallback.directive';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    MaterialExtensionsModule,
    FormlyModule,
    FormlyMaterialModule,
    NgProgressModule,
    NgProgressRouterModule,
    NgProgressHttpModule,
    NgxPermissionsModule,
    ToastrModule,
    TranslateModule,
    NgxFileDropModule,
    NgxDocViewerModule,
    AutosizeModule,
    LocalSourcesModule,
  ],
  declarations: [
    BreadcrumbComponent,
    PageHeaderComponent,
    ErrorCodeComponent,
    JobStatusComponent,
    TopSearchComponent,
    SpinnerComponent,
    QuizComponent,
    SearchFilterPipe,
    BadgeComponent,
    MultiSummaryComponent,
    ReferencesComponent,
    AddToListButtonComponent,
    MaterialsPreviewComponent,
    VideoPlayerComponent,
    ImageViewerComponent,
    YoutubePlayerComponent,
    FileDownloadComponent,
    LinkComponent,
    ImagePreviewModalComponent,
    BotStatusBadgeComponent,
    ExportToSelectorComponent,
    ImagePreviewModalComponent,
    BotStatusBadgeComponent,
    SourcePreviewModalComponent,
    SourcePreviewButtonComponent,
    UsersSelectorComponent,
    DragAndDropComponent,
    ProgressBarComponent,
    NoticeComponent,
    DisableControlDirective,
    TextEllipsisDirective,
    HasRoleDirective,
    FeatureCheckDirective,
    MinRoleDirective,
    AppExpandableDirective,
    StarButtonComponent,
    SafeUrlPipe,
    ToObservablePipe,
    FormattedDatePipe,
    TruncatePipe,
    DateFromNowPipe,
    FirstUpperPipe,
    SanitizeStrPipe,
    NoSanitizePipe,
    SimplePaginatorComponent,
    SourceFolderFormComponent,
    CounterBadgeComponent,
    FilePreviewModalComponent,
    PreviewModalComponent,
    DocumentComponent,
    GoodPromptButtonComponent,
    ShowOriginalResourcesComponent,
    OriginalResourceModalComponent,
    BotResultsComponent,
    OriginalContentComponent,
    SourceViewerSidebarComponent,
    ConversationDndComponent,
    InputFilePreviewComponent,
    SpeechRecognitionComponent,
    ConversationInputComponent,
    SingleMessageComponent,
    MessageControlsComponent,
    BotQuizAnswersComponent,
    QuizReferenceComponent,
    TalkingAvatarComponent,
    StreamPlayerComponent,
    TalkingAvatarPlaylistComponent,
    PromptHintsMenuComponent,
    BotSearchComponent,
    SourceSearchModalComponent,
    DynamicStatusComponent,
    ImageFallbackDirective,
  ],
  exports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    MaterialExtensionsModule,
    FormlyModule,
    FormlyMaterialModule,
    NgProgressModule,
    NgProgressRouterModule,
    NgProgressHttpModule,
    NgxPermissionsModule,
    ToastrModule,
    TranslateModule,
    NgxFileDropModule,
    BreadcrumbComponent,
    PageHeaderComponent,
    ErrorCodeComponent,
    JobStatusComponent,
    TopSearchComponent,
    SpinnerComponent,
    QuizComponent,
    SearchFilterPipe,
    BadgeComponent,
    MultiSummaryComponent,
    ReferencesComponent,
    AddToListButtonComponent,
    MaterialsPreviewComponent,
    VideoPlayerComponent,
    ImageViewerComponent,
    YoutubePlayerComponent,
    FileDownloadComponent,
    LinkComponent,
    ImagePreviewModalComponent,
    BotStatusBadgeComponent,
    ExportToSelectorComponent,
    ImagePreviewModalComponent,
    BotStatusBadgeComponent,
    SourcePreviewModalComponent,
    SourcePreviewButtonComponent,
    UsersSelectorComponent,
    DragAndDropComponent,
    ProgressBarComponent,
    NoticeComponent,
    StarButtonComponent,
    DisableControlDirective,
    TextEllipsisDirective,
    HasRoleDirective,
    FeatureCheckDirective,
    MinRoleDirective,
    AppExpandableDirective,
    SafeUrlPipe,
    ToObservablePipe,
    FormattedDatePipe,
    TruncatePipe,
    DateFromNowPipe,
    FirstUpperPipe,
    SanitizeStrPipe,
    NoSanitizePipe,
    SimplePaginatorComponent,
    SourceFolderFormComponent,
    CounterBadgeComponent,
    FilePreviewModalComponent,
    GoodPromptButtonComponent,
    ShowOriginalResourcesComponent,
    OriginalResourceModalComponent,
    BotResultsComponent,
    SingleMessageComponent,
    MessageControlsComponent,
    TalkingAvatarComponent,
    StreamPlayerComponent,
    TalkingAvatarPlaylistComponent,
    InputFilePreviewComponent,
    SpeechRecognitionComponent,
    ConversationDndComponent,
    ConversationInputComponent,
    OriginalContentComponent,
    PromptHintsMenuComponent,
    BotSearchComponent,
    SourceSearchModalComponent,
    LocalSourcesModule,
    SourceViewerSidebarComponent,
    DynamicStatusComponent,
    ImageFallbackDirective,
  ],
  providers: [FileUploadService],
})
export class SharedModule {}
