<div class="d-flex w-full h-full">
  <mat-card class="m-auto" style="max-width: 380px;">
    <mat-card-header class="m-b-24">
      <mat-card-title>
        {{'register_welcome' | translate}}, <br />
        {{'register_title' | translate}}
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <form class="form-field-full" [formGroup]="registerForm">
        <div class="form-field-container">
          <label for="username-input" class="form-field-label">{{'username' | translate}}</label>
          <mat-form-field appearance="outline">
            <input id="username-input" matInput formControlName="username"
                   [placeholder]="'Enter ' + ('username' | translate)" required>
            <mat-error *ngIf="registerForm.get('username')?.invalid">
              <span>{{'validations.required' | translate}}</span>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-field-container">
          <label for="password-input" class="form-field-label">{{'password' | translate}}</label>
          <mat-form-field appearance="outline">
            <input id="password-input" matInput type="password" formControlName="password"
                   [placeholder]="'Enter ' + ('password' | translate)" required>
            <mat-error *ngIf="registerForm.get('password')?.invalid">
              <span>{{'validations.required' | translate}}</span>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-field-container">
          <label for="confirm-password-input" class="form-field-label">{{'confirm_password' |
            translate}}</label>
          <mat-form-field appearance="outline">
            <input id="confirm-password-input" matInput type="password"
                   formControlName="confirmPassword"
                   [placeholder]="'Confirm ' + ('password' | translate)" required>
            <mat-error *ngIf="registerForm.get('confirmPassword')?.hasError('required')">
              <span>{{'validations.required' | translate}}</span>
            </mat-error>
            <mat-error *ngIf="registerForm.get('confirmPassword')?.hasError('mismatch')"
                       translate [translateParams]="{value: 'login.password' | translate}">
              <span>{{'validations.inconsistent'}}</span>
            </mat-error>
          </mat-form-field>
        </div>

        <mat-checkbox>{{'read_and_agree' | translate}}</mat-checkbox>

        <button class="w-full m-y-16" mat-raised-button color="accent">
          {{'register' | translate}}
        </button>

        <div>{{'have_an_account' | translate}}?
          <a routerLink="/auth/login">{{'login' | translate}}</a>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>