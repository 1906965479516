import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ConversationApi } from '@core/api/conversations/api';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IGNORE_ERRORS_KEY } from '@core';

@Injectable({
  providedIn: 'root',
})
export class ReferencesService {
  constructor(private _http: HttpClient) {}


  async processReferences(
    conversationId: string,
    messageId: string,
    text: string,
    callback: (data: any) => void
  ): Promise<void> {
      const data = await this.getReferences(conversationId, messageId, [text]);
      callback(data);
  }

  private async getReferences(conversationId: string, messageId: string, text: string | string[]) {
    return new Promise((resolve, reject) => {
      const { url, body } = ConversationApi.textProcessorForReferences(
        conversationId,
        messageId,
        text
      );
      const headers = new HttpHeaders().set(IGNORE_ERRORS_KEY, 'true');
      this._http.post(url, body, { headers }).subscribe(data => {
        resolve(data);
      });
    });
  }
}
