<div class="container" *ngIf="isAvailable()">
  <!-- iOS-style toggle switch -->
  <div
    class="button-container"
    [ngClass]="{
      'button-container--hidden': service.isClosed,
      'button-container--visible': !service.isClosed
    }"
  >
    <div class="toggle-switch" [class.active]="!service.isClosed">
      <input
        type="checkbox"
        id="avatar-toggle"
        class="toggle-switch__input"
        [checked]="!service.isClosed"
        (change)="toggleClose()"
      />
      <label
        for="avatar-toggle"
        class="toggle-switch__label"
        [matTooltip]="service.isClosed ? 'Activate Avatar' : 'Hide Avatar'"
      >
        <span class="toggle-switch__thumb">
          <mat-icon>{{ service.isClosed ? 'face' : 'face_retouching_natural' }}</mat-icon>
        </span>
      </label>
    </div>
  </div>

  <!-- Video container -->
  <div *ngIf="!service.isClosed" class="container--video cropped-video">
    <app-stream-player
      class="cropped-video__content"
      [ngClass]="{ hidden: isPlaceHolderVisible }"
      [streamData]="streamData"
      (loaded)="isPlaceHolderVisible = !$event"
    ></app-stream-player>
    <video
      class="cropped-video__content"
      #placeholderVideo
      loop
      playsinline
      width="900"
      height="506"
      [ngClass]="{ hidden: !isPlaceHolderVisible }"
      src="/assets/avatar/new-prev.mp4"
    ></video>
  </div>
</div>
