import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ConfluenceService } from '@shared/services/integrations/confluence.service';
import {
  INITIAL_CONFLUENCE_PAGING,
  TConfluenceImport,
  TConfluencePage,
  TConfluencePaginated,
  TConfluencePaging,
  TConfluenceSpace,
} from '@core/api/sources/types';
import { Subscription } from 'rxjs';
import { TSimplePaginatorNav } from '@shared/components/simple-paginator/simple-paginator.component';
import { allowedStatuses } from '../../../../enums/status';
import { CommonService } from '@shared/services/common.service';
import { ConfluenceItemSelectionBase } from './confluence-item-selection-base';
import { Router } from '@angular/router';

export type TConfluenceSteps = 'spaces' | 'pages';

@Component({
  selector: 'app-new-confluence-folder-modal',
  templateUrl: './new-confluence-folder-modal.component.html',
  styleUrls: ['./new-confluence-folder-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush, // prevent overload change detection
})
export class NewConfluenceModalComponent extends ConfluenceItemSelectionBase implements OnDestroy {

  private _subs: Subscription[] = [];
  protected spaces: TConfluencePaginated<TConfluenceSpace> | null = null;
  protected pages: TConfluencePaginated<TConfluencePage> | null = null;

  protected isProcessing = {
    posting: false,
    spaces: false,
    pages: false,
  };
  protected step: 'select' | 'create_folder' = 'select';
  protected newFolderData: { name: string, status: typeof allowedStatuses } | null = null;
  protected errorWhenFetching = false;

  constructor(
    protected dialogRef: MatDialogRef<NewConfluenceModalComponent>,
    private _confluenceService: ConfluenceService,
    private _commonService: CommonService,
    private _router: Router,
  ) {
    super();
    this._subs.push(
      this._confluenceService.getSpacesAsObservable().subscribe((value) => {
        this.spaces = value;
        this.cdr.markForCheck();
      }),
    );

    this._subs.push(
      this._confluenceService.getPagesAsObservable().subscribe(value => {
        this.pages = value;
        this.cdr.markForCheck();
      }),
    );

    this._subs.push(
      this._confluenceService.isLoadingObservable.subscribe(isLoading => {
        this.isProcessing[isLoading.step] = isLoading.isLoading;
        this.cdr.markForCheck();
      }),
    );

    this._subs.push(
      this._confluenceService.getErrorAsObservable().subscribe((value) => {
        this.errorWhenFetching = value;
        this.cdr.markForCheck();
      }),
    );

    this._confluenceService.getSpaces(INITIAL_CONFLUENCE_PAGING);
  }

  protected selectSpace(space: TConfluenceSpace) {
    const p = { ...INITIAL_CONFLUENCE_PAGING };
    this.getPages(space, p);
  }

  protected getPages(selectedSpace: TConfluenceSpace, paging: TConfluencePaging) {
    this._confluenceService.getPages(selectedSpace.key, paging);
    this.selectedSpace = selectedSpace;
  }

  goNext() {
    if (this.step === 'select') {
      this.step = 'create_folder';
    } else {
      this.submit();
    }
  }

  isNextDisabled() {
    if (this.isProcessing.posting) {
      return true;
    }
    if (this.step === 'select') {
      return this.selected.pages.length === 0 && this.selected.spaces.length === 0;
    } else {
      return this.newFolderData === null;
    }
  }

  protected submit() {
    const data: TConfluenceImport = {
      folderName: this.newFolderData!.name,
      folderStatus: this.newFolderData!.status.toString(),
      pagesIds: this.selected.pages.map(item => {
        return item.id;
      }),
      spacesIds: this.selected.spaces.map(item => {
        return item.key;
      }),
    };
    this.isProcessing.posting = true;
    this._subs.push(this._confluenceService.importFromConfluence(data).subscribe({
        next: () => {
          this._commonService.openWarningSnackBar('Import started');
          this.dialogRef.close();
          this.isProcessing.posting = false;
          this.cdr.markForCheck();
        },
        error: (err) => {
          console.error(err);
          this._commonService.openErrorSnackBar('Import failed');
          this.isProcessing.posting = false;
          this.cdr.markForCheck();
        },
      }),
    );
  }

  protected handlePageNavigationEvent(step: TConfluenceSteps, nav: TSimplePaginatorNav) {
    const pagination = { ...INITIAL_CONFLUENCE_PAGING };
    pagination.start = nav === 'next'
      ? (step === 'spaces' ? this.spaces!.start : this.pages!.start) + INITIAL_CONFLUENCE_PAGING.limit
      : (step === 'spaces' ? this.spaces!.start : this.pages!.start) - INITIAL_CONFLUENCE_PAGING.limit;

    if (step === 'spaces') {
      this._confluenceService.getSpaces(pagination);
      this.pages = null;
      this.selectedSpace = undefined;
    } else if (step === 'pages') {
      this._confluenceService.getPages(this.selectedSpace!.key, pagination);
    }

  }

  protected goToSettings() {
    this.dialogRef.close();
    this._router.navigateByUrl(`/profile/api-settings`);
  }

  ngOnDestroy(): void {
    this._subs.forEach(value => value.unsubscribe());
  }

}
