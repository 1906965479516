import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { SLRCreateProjectDto, SLRProjectDto } from '../../api/dtos/projects.dto';
import { ApiProjectsService } from '../../api/servces/slr/api-projects.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  private projectSubject = new Subject<SLRProjectDto>();
  private projectsList = new Subject<SLRProjectDto[]>();
  private selectedProjectSubject = new ReplaySubject<SLRProjectDto | null>();

  constructor(private _apiProject: ApiProjectsService) {}

  private _selectedProjectId: number | null = null;

  get selectedProjectId(): number | null {
    return this._selectedProjectId;
  }

  get projectData$(): Observable<SLRProjectDto> {
    return this.projectSubject.asObservable();
  }

  get selectedProject$(): Observable<SLRProjectDto | null> {
    return this.selectedProjectSubject.asObservable();
  }

  get projectsLists$(): Observable<SLRProjectDto[]> {
    return this.projectsList.asObservable();
  }

  setSelectedProjectId(projectId: number | null) {
    this._selectedProjectId = projectId;
  }

  setSelectedProject(project: SLRProjectDto | null) {
    this.selectedProjectSubject.next(project);
  }

  fetchProject() {
    if (!this._selectedProjectId) return;
    this._apiProject.getByID(this._selectedProjectId).subscribe(project => {
      this.projectSubject.next(project);
    });
  }

  fetchProjects(): void {
    this._apiProject.index().subscribe(projects => {
      this.projectsList.next(projects);
    });
  }

  createProject(
    project: SLRCreateProjectDto,
    callback?: (newProject: SLRProjectDto) => void
  ): void {
    this._apiProject.create(project).subscribe(newProject => {
      this.fetchProjects();
      if (callback) {
        callback(newProject);
      }
    });
  }

  updateProject(project: SLRProjectDto, callback?: (updatedProject: SLRProjectDto) => void): void {
    this._apiProject.update(project).subscribe(updatedProject => {
      this.fetchProjects();
      this.selectedProjectSubject.next(updatedProject);
      if (callback) {
        callback(updatedProject);
      }
    });
  }
}
