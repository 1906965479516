import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { TFolderItem } from '@core/interfaces/TFolderItem';

import { CommonService } from '@shared/services/common.service';
import { FoldersService } from '@shared/services/folders.service';

@Component({
  selector: 'app-edit-source-folder-modal',
  templateUrl: './edit-source-folder-modal.component.html',
  styleUrls: ['./edit-source-folder-modal.component.scss'],
})
export class EditSourceFolderModalComponent implements OnInit {
  public editFolderForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private _folderService: FoldersService,
    private _commonService: CommonService,
    private dialogRef: MatDialogRef<EditSourceFolderModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { source: TFolderItem }
  ) {
    this.editFolderForm = this.fb.nonNullable.group({
      name: [''],
    });
  }

  public ngOnInit(): void {
    if (this.data.source) {
      this.editFolderForm.patchValue({
        name: this.data.source.name,
      });
    }
  }

  public get name() {
    return this.editFolderForm.get('name')!;
  }

  public updateFolder(): void {
    const updatedFolder = {
      ...this.data.source,
      name: this.name.value,
    };
    this._folderService.updateFolder(updatedFolder, () => {
      this._commonService.openSuccessSnackBar('Folder updated');
      this.dialogRef.close();
    });
  }
}
