export class StringHelper {
  public static buildQueryParams(query?: { [key: string]: any }): string {
    if (!query) {
      return '';
    }

    const params = new URLSearchParams();

    Object.entries(query).forEach(([key, value]) => {
      if (value !== undefined) {
        params.append(key, String(value));
      }
    });

    return `?${params.toString()}`;
  }

  /**
   * Truncates text to approximately the specified number of words
   * @param text The text to truncate
   * @param wordCount The maximum number of words to include (default: 10)
   * @returns The truncated text with ellipsis if needed
   */
  public static truncateToWords(text: string, wordCount: number = 10): string {
    if (!text) return '';

    // Split by words and take first n words
    const words = text.split(/\s+/);
    const truncated = words.slice(0, wordCount).join(' ');

    // Add ellipsis if text was truncated
    return words.length > wordCount ? `${truncated}...` : truncated;
  }
}
