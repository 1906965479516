import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '@core';
import { map } from 'rxjs/operators';

@Directive({
  selector: '[allowedRole]',
})
export class HasRoleDirective implements OnDestroy {
  private subscription: Subscription | undefined;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService,
  ) {}

  @Input()
  set allowedRole(role: string) {
    // Clean up previous subscription if it exists
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.viewContainer.clear();
    }

    this.subscription = this.authService.user().pipe(
      map(user => user.roles?.includes(role)),
    ).subscribe(isVisible => {
      if (isVisible) {
        if (!this.viewContainer.length) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      } else {
        this.viewContainer.clear();
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
