import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import {
  SourceMaterialStatus,
  TMaterialSourceFolder,
  TSourceMaterial,
} from '@core/api/materials/types';
import { CommonService } from '@shared/services/common.service';
import { SourceMaterialsService } from '@shared/services/source-materials.service';
import { Subscription } from 'rxjs';
import { ConfirmationModalComponent } from '../../../modals/confirmation-modal/confirmation-modal.component';
import { SourceFolderModalComponent } from '../../../modals/source-folder-modal/source-folder-modal.component';

@Component({
  selector: 'app-materials-list',
  templateUrl: './materials-list.component.html',
  styleUrls: ['./materials-list.component.scss'],
})
export class MaterialsListComponent implements OnInit, OnDestroy {
  protected folder: TMaterialSourceFolder | null = null;
  protected selectedMaterial: TSourceMaterial | null = null;
  private _subs: Subscription[] = [];
  protected showList = false;
  private folderId: string | null = null;

  @ViewChild(MatSort) sort: MatSort = {} as MatSort;
  dataSource = new MatTableDataSource<TSourceMaterial>([]);

  constructor(
    protected sourceMaterialService: SourceMaterialsService,
    private dialog: MatDialog,
    private commonService: CommonService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this._subs.push(
      this.activatedRoute.url.subscribe((url: UrlSegment[]) => {
        this.folderId = url[1]?.parameters?.folder_id;
        this.getFolderContent();
      })
    );
  }

  ngOnInit(): void {
    this._subs.push(
      this.sourceMaterialService.materials$.subscribe(value => {
        this.folder = value;
        if (value && value.resources) {
          this.dataSource.data = value.resources;
          this.selectMaterial();
        }
      })
    );
  }

  private selectMaterial() {
    const materialId = this.activatedRoute.snapshot.paramMap.get('material_id');
    if (materialId) {
      this.selectedMaterial =
        this.folder?.resources?.find(folder => folder._id === materialId) ?? null;
      return;
    }
    this.selectedMaterial = null;
  }

  editMaterial(item: TSourceMaterial | null = null) {
    this.router.navigate([
      'sources/materials',
      { folder_id: this.folder!._id, material_id: item?._id },
    ]);
  }

  uploadMaterial() {
    this.selectedMaterial = {} as TSourceMaterial;
  }

  editFolder() {
    const dialogSubscription = this.dialog.open(SourceFolderModalComponent, { data: this.folder });
    dialogSubscription.componentInstance.folderChanged.subscribe(value => {
      Object.assign(this.folder!, value);
    });
  }

  deleteItem(item: TSourceMaterial) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: { text: `Are you sure you want to delete "${item.name}"?` },
    });
    const dialogSubscription = dialogRef.componentInstance.actionCallback.subscribe(() => {
      this.sourceMaterialService.delete(item._id, () => {
        dialogRef.close();
        this.commonService.openSuccessSnackBar(`Material "${item.name}" was deleted`);
      });

      dialogSubscription.unsubscribe();
    });
  }

  toggleStatus(item: TSourceMaterial) {
    item.isProcessing = true;
    const data = { ...item } as TSourceMaterial;
    data.status =
      item.status === SourceMaterialStatus.Active
        ? SourceMaterialStatus.Inactive
        : SourceMaterialStatus.Active;
    const sub = this.sourceMaterialService.edit(item._id, data).subscribe(updatedItem => {
      if (updatedItem.status === data.status) {
        // is status as expecting
        item.status = data.status;
        this.commonService.openSuccessSnackBar('Material status updated');
      } else {
        this.commonService.openErrorSnackBar('Material status update failed');
      }
      item.isProcessing = false;
      sub.unsubscribe();
    });
  }

  public handleSortChange(): void {
    this.dataSource.sort = this.sort;
  }

  private getFolderContent() {
    if (this.folderId) {
      this.sourceMaterialService.getAllForSelectedFolder(this.folderId);
      this.showList = true;
      return;
    }
    this.folder = null;
    this.showList = false;
  }

  ngOnDestroy() {
    this._subs.forEach(value => value.unsubscribe());
  }
}
