<form class="form-field-full" [formGroup]="newFolderForm">
  <div class="form-inputs" *ngIf="!isDisabled">
    <div class="form-field-container">
      <label for="folder-name-input" class="form-field-label">Folder name</label>
      <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
        <input
               id="folder-name-input"
               matInput
               placeholder="Enter folder name"
               type="text"
               formControlName="name"
               required />
      </mat-form-field>
    </div>

    <div class="form-field-container">
      <label for="status-select" class="form-field-label">Status</label>
      <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
        <mat-select id="status-select" formControlName="status" placeholder="Select status">
          <mat-option *ngFor="let st of statuses" [value]="st">
            {{ st | titlecase }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <app-spinner *ngIf="isDisabled" message="Wait..."></app-spinner>
</form>