import { Component, OnDestroy, OnInit } from '@angular/core';
import { VeevaService } from '@shared/services/integrations/veeva.service';
import { CommonService } from '@shared/services/common.service';
import { FormBuilder, Validators } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';
import { allowedStatuses } from '../../../../enums/status';
import { FoldersService } from '@shared/services/folders.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TFolder } from '@core/interfaces/Endpoints';
import { MatDialogRef } from '@angular/material/dialog';
import { IVeevaItem } from '@core/api/sources/types';
import { IPaginationDto } from '../../../../features/api/dtos/pagination.dto';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-folder-modal',
  templateUrl: './new-veeva-modal.component.html',
  styleUrls: ['./new-veeva-modal.component.scss'],
})
export class NewVeevaModalComponent implements OnInit, OnDestroy {
  protected facets: string[] = [];
  protected facetsTypes: string[] = [];
  protected isLoading = false;
  protected importError = false;
  protected results: IVeevaItem[] = [];
  protected selectedResults: { [key: number]: IVeevaItem } = {};
  protected importAllByQuery = false;
  protected readonly Object = Object;
  protected tryAgainButton = false;
  protected importStarted = false;

  protected searchForm = this.fb.nonNullable.group({
    facets: ['', [Validators.required]],
    facetsType: ['', [Validators.required]],
    text: ['Vedolizumab Entyvio', [Validators.required]],
  });
  protected folderForm = this.fb.nonNullable.group({
    name: ['New Veeva folder', [Validators.required]],
    status: [allowedStatuses[0], [Validators.required]],
  });
  protected readonly statuses = allowedStatuses;
  private _subs: Subscription[] = [];
  protected pagination: IPaginationDto | null = null;
  private _selectedFolderId: string | null = null;

  constructor(
    private _veevaService: VeevaService,
    private _commonService: CommonService,
    private fb: FormBuilder,
    private _folderService: FoldersService,
    private _dialogRef: MatDialogRef<NewVeevaModalComponent>,
    private router: Router
  ) {}

  ngOnInit() {
    this._subs.push(
      this._veevaService.results$.subscribe(data => {
        this.results.push(...data.data);
        this.pagination = data.pagination;
        this.isLoading = false;
        this.searchForm.enable();
      })
    );
    this.getFacets();
  }

  protected getFacets() {
    this.tryAgainButton = false;
    this.searchForm.disable();
    this._subs.push(
      forkJoin({
        facets: this._veevaService.fetchFacets(),
        facetsTypes: this._veevaService.fetchTypeFacets(),
      }).subscribe({
        next: ({ facets, facetsTypes }) => {
          this.facets = facets.statusValues;
          this.facetsTypes = facetsTypes.typeValues;
        },
        error: () => {
          this._commonService.openErrorSnackBar('Failed to fetch facets or type facets');
          this.tryAgainButton = true;
          this.searchForm.enable();
        },
        complete: () => {
          this.searchForm.enable();
        },
      })
    );
  }

  removeSelectedItem(id: number): void {
    delete this.selectedResults[id];
  }

  onSearch() {
    this.isLoading = true;
    this.searchForm.disable();
    const searchQuery = {
      ...this.searchForm.value,
      page: (this.pagination?.page ?? 0) + 1,
      limit: 100,
    };
    this._veevaService.fetchData(searchQuery);
  }

  selectedResultsAreValid(): boolean {
    return Object.values(this.selectedResults).some(value => value);
  }

  isSubmitValid() {
    return this.folderForm.valid && this.canProceedImport();
  }

  onSubmit() {
    this.isLoading = true;
    this.importError = false;
    this.importStarted = false;
    if (!this.folderForm.valid) return;
    this.creatFolder().then(folderId => {
      this._selectedFolderId = folderId;
      this.runImport(folderId).subscribe({
        next: () => {
          this._commonService.openSuccessSnackBar('Import started');
          this.isLoading = false;
          this.importStarted = true;
        },
        error: () => {
          this.importError = true;
          this._commonService.openErrorSnackBar('Import failed');
          this.isLoading = false;
        },
        complete: () => {
          this.importStarted = true;
          this.isLoading = false;
        },
      });
    });
  }

  runImport(folderId: string) {
    if (this.importAllByQuery) {
      return this._veevaService.importAllByQuery(folderId, this.searchForm.value);
    } else {
      const ids = Object.values(this.selectedResults).map(item => item.id);
      return this._veevaService.importSelectedItems(folderId, ids);
    }
  }

  onImportAllByQueryChange(event: MatCheckboxChange): void {
    if (event.checked) {
      this.selectedResults = {};
    }
  }

  creatFolder(): Promise<string> {
    return new Promise(resolve => {
      const newFolderItem: TFolder = this.folderForm.value as TFolder;
      this._folderService.addNewFolder(newFolderItem, folderId => resolve(folderId));
    });
  }

  onCheckboxChange(event: MatCheckboxChange, item: IVeevaItem): void {
    if (event.checked) {
      this.selectedResults[item.id] = item;
    } else {
      delete this.selectedResults[item.id];
    }
  }

  onClose() {
    this._dialogRef.close();
    this.router.navigate(['/sources'], { queryParams: { folder_id: this._selectedFolderId } });
  }

  canProceedImport() {
    if (this.folderForm.invalid) return false;
    return this.importAllByQuery || this.selectedResultsAreValid();
  }

  ngOnDestroy() {
    this._subs.forEach(sub => sub.unsubscribe());
  }

}
