<app-spinner *ngIf="botMessagesService.isLoading"></app-spinner>

<div class="container" *ngIf="!botMessagesService.isLoading">
  <app-talking-avatar-playlist
    *ngIf="showTalkingAvatar"
    [conversation]="adaptedBotConversation"
  ></app-talking-avatar-playlist>
  <div class="toolbar-container" *ngIf="adaptedBotConversation?.messages">
    <app-star-button
      [canStar]="canStar"
      [isStarred]="adaptedBotConversation!.favorite"
      [onToggle]="toggleStarConversation.bind(this)"
    ></app-star-button>
    <app-export-to-selector [isDisabled]="botMessagesService.isWriting"></app-export-to-selector>
  </div>
  <div
    *ngFor="let message of adaptedBotConversation?.messages; trackBy: trackByMessage; index as i"
  >
    <div class="message-{{ message.author }} {{ message.type }}">
      <div class="bot-icon" *ngIf="message.author === 'assistant'">
        <mat-icon svgIcon="bots"></mat-icon>
      </div>
      <app-message-controls
        [message]="message"
        [bot]="bot"
        [hideControls]="['error', 'quiz'].includes(message.type)"
      ></app-message-controls>
      <div class="message-container">
        <app-single-message
          [isLoading]="botMessagesService.isWriting"
          [message]="message"
        ></app-single-message>
        <p *ngIf="botFinalMessage && message.author === 'assistant' && !message?.isWriting">
          {{ botFinalMessage }}
        </p>
        <ng-container *ngIf="message && message.paragraphs">
          <app-bot-quiz-answers
            [conversationId]="conversationId"
            [asResults]="adaptedBotConversation?.type === 'results'"
            *ngIf="message.paragraphs[0]?.quizQuestions"
            [message]="message"
          ></app-bot-quiz-answers>
        </ng-container>
        <app-references
          *ngIf="!['error', 'quiz'].includes(message.type)"
          [lang]="adaptedBotConversation!.language"
          [sources]="message.sources"
        ></app-references>

      </div>
    </div>
    <app-original-content
      *ngIf="i === 0"
      [content]="adaptedBotConversation?.content"
      [sources]="message.sources"
    >
    </app-original-content>
  </div>
</div>
