import { Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TBotItem } from '@core/interfaces/TBotItem';
import { BotsService } from '@shared/services/bots.service';
import { Subscription } from 'rxjs';
import { CommonService } from '@shared/services/common.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-prompt-hints-component',
  templateUrl: './prompt-hints-modal.component.html',
  styleUrls: ['./prompt-hints-modal.component.scss'],
})
export class PromptHintsModalComponent implements OnDestroy {
  private _subs: Subscription[] = [];
  protected form: FormGroup;
  protected readonly maxLength: number = 250;
  protected readonly minLength: number = 10;
  @ViewChild('hintsList') hintList: ElementRef | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { bot: TBotItem },
    private _dialogRef: MatDialogRef<PromptHintsModalComponent>,
    private _botsService: BotsService,
    private _commonService: CommonService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      hints: this.fb.array([]),
    });
    const hints = this.data.bot.promptHints?.length === 0 ? [''] : this.data.bot.promptHints!; // Add empty filed if no prompts in bot
    hints.forEach((hint: string) => {
      this.addHint(hint);
    });
  }

  get hints(): FormArray {
    return this.form.get('hints') as FormArray;
  }

  addHint(value = ''): void {
    const hintControl = this.fb.group({
      hintText: [
        value,
        [
          Validators.required,
          Validators.maxLength(this.maxLength),
          Validators.minLength(this.minLength),
        ],
      ],
    });
    this.hints.push(hintControl);
  }

  scrollToBottom() {
    setTimeout(() => {
      this.hintList?.nativeElement.scrollTo({
        top: this.hintList.nativeElement.scrollHeight,
        behavior: 'smooth',
      });
    });
  }

  removeHint(index: number): void {
    this.hints.removeAt(index);
  }

  cancel() {
    this._dialogRef.close();
  }

  confirm() {
    this.form.disable();
    const hints: string[] = this.hints.value.map((hint: any) => hint.hintText);
    this._subs.push(
      this._botsService.updatePromptHints(this.data.bot._id, hints).subscribe(() => {
        this.data.bot.promptHints = hints;
        this._commonService.openSuccessSnackBar('Changes saved successfully');
        this.form.enable();
        this.cancel();
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }
}
