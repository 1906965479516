<ng-container *ngIf="content || sources?.length">
  <div class="sources-grid">
    <ng-container *ngFor="let source of displaySources; let i = index">
      <div class="source-tile numbered-tile" *ngIf="i < 3" [attr.data-number]="i + 1"
           (click)="openSourceViewer(i)">
        <div class="source-content">
          <div class="source-text">{{ source.preview }}</div>
          <div class="source-actions">
            <span class="view-source-text">View full text</span>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="source-tile more-sources" *ngIf="hasMoreSources">
      <div class="more-sources-content">
        <mat-icon>more_horiz</mat-icon>
        <div class="more-sources-text">{{ moreSources.length }}+ more sources</div>
      </div>
      <div class="more-sources-list">
        <div *ngFor="let source of moreSources; let i = index"
             class="more-source-item"
             [attr.data-number]="i + 4"
             (click)="openSourceViewer(i + 3)">
          <span class="more-source-preview" [title]="source.fullPreview">{{ source.preview }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- Source viewer sidebar -->
<app-source-viewer-sidebar
                           [data]="sidebarData"
                           [visible]="sidebarVisible"
                           (closeSidebar)="closeSidebar()">
</app-source-viewer-sidebar>