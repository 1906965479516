<div class="original-resources-modal">
  <h3 class="mat-h3">Original sources</h3>
  <div class="items">
    <div *ngFor="let detail of data.sourceDetails" class="item">
      <div>
        <strong>ID:</strong> {{ detail._id }}
      </div>
      <div *ngIf="detail.fileLink">
        <strong>File Link:</strong> <a class="link" [href]="detail.fileLink" target="_blank"> {{
          detail.fileLink }}</a>
      </div>
      <div>
        <strong>Folder:</strong>
        <ul>
          <li *ngFor="let folder of detail.folder">{{ folder }}</li>
        </ul>
      </div>
      <div>
        <strong>Name:</strong> {{ detail.name }}
      </div>
      <div *ngIf="detail.references">
        <strong>References:</strong> {{ detail.references }}
      </div>
      <div *ngIf="detail.embeddingContent">
        <strong>Embedding Content:</strong> {{ detail.embeddingContent }}
      </div>
      <hr>
    </div>
  </div>

  <div class="d-flex align-items-right justify-content-between m-t-16">
    <button mat-raised-button color="accent" (click)="cancel()">
      Close
    </button>
  </div>
</div>