import { Injectable } from '@angular/core';
import { BaseApiService } from '../base-api.service';
import { AbstractResponseDto, SLRAbstractDto } from '../../dtos/abstracts.dto';
import { TQuery } from '../../types/general.types';
import { IPaginatedResponseDto } from '../../dtos/pagination.dto';
import { TFiltersDto } from '../../dtos/filters.dto';

@Injectable({ providedIn: 'root' })
export class ApiAbstractsService extends BaseApiService {
  protected apiPath = 'abstracts';

  getForProject(projectId: number, paging?: TQuery) {
    return this.get<IPaginatedResponseDto<SLRAbstractDto[]>>(`listByProject/${projectId}`, paging);
  }

  getByID(id: string) {
    return this.get<AbstractResponseDto>(id);
  }

  create(abstract: SLRAbstractDto) {
    return this.post<SLRAbstractDto, SLRAbstractDto>(
      `addToProject/${abstract.projectId}`,
      abstract
    );
  }

  update(id: number, abstract: SLRAbstractDto) {
    return this.put<SLRAbstractDto, SLRAbstractDto>(id.toString(), abstract);
  }

  fetchFilters(projectId: number) {
    return this.get<TFiltersDto>(`listByProject/${projectId}/filtringAndSorting`);
  }

  uploadFile(file: File, abstractId: number) {
    const formData = new FormData();
    formData.append('file', file);
    return this.post<FormData, void>(`${abstractId}/uploadPublication`, formData);
  }
}
