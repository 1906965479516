import { environment } from '@env/environment';

import { DeleteRequestData, GetRequestData, PostRequestData, PutRequestData } from '../utils';
import {
  SourceUpdateBody,
  TConfluenceImport,
  TConfluencePaging,
  TExternalReference,
  TVeevaImportIdsDto,
} from './types';
import { StringHelper } from '../../../features/helpers/string.helper';

export class SourcesApi {
  static updateSource(body: SourceUpdateBody): PutRequestData<SourceUpdateBody> {
    const sourceId = body._id;
    return {
      url: `${environment.backendURL}/api/source/${sourceId}`,
      body,
    };
  }

  static getConfluenceSpaces(paging: TConfluencePaging): GetRequestData {
    return {
      url: `${environment.backendURL}/api/confluence/spaces?limit=${paging.limit}&start=${paging.start}`,
    };
  }

  static getConfluencePages(spaceKey: string, paging: TConfluencePaging): GetRequestData {
    return {
      url: `${environment.backendURL}/api/confluence/spaces/${spaceKey}/pages?limit=${paging.limit}&start=${paging.start}`,
    };
  }

  static import(body: TConfluenceImport): PostRequestData<TConfluenceImport> {
    return {
      url: `${environment.backendURL}/api/confluence/import`,
      body,
    };
  }

  static getExternalReferences(sourceId: string): GetRequestData {
    return {
      url: `${environment.backendURL}/api/source/${sourceId}`,
    };
  }

  static addExternalReference(
    sourceId: string,
    body: TExternalReference
  ): { body: { reference: TExternalReference }; url: string } {
    return {
      url: `${environment.backendURL}/api/source/externalReferences/${sourceId}`,
      body: { reference: body },
    };
  }

  static searchReference(sourceId: string, query: string): GetRequestData {
    return {
      url: `${environment.backendURL}/api/source/externalReferences/${sourceId}/search?query=${query}`,
    };
  }

  static deleteExternalReference(sourceId: string, referenceId: string): DeleteRequestData {
    return {
      url: `${environment.backendURL}/api/source/externalReferences/${sourceId}/${referenceId}`,
    };
  }

  static getEmbeddings(sourceId: string): GetRequestData {
    return {
      url: `${environment.backendURL}/api/source/embeddings/${sourceId}`,
    };
  }

  static attachReference(
    embeddingId: string,
    referenceId: string
  ): PostRequestData<{ embeddingId: string; referenceId: string }> {
    return {
      url: `${environment.backendURL}/api/source/externalReferences/embeddings/addExisting`,
      body: { embeddingId, referenceId },
    };
  }

  static detachReference(embeddingId: string, referenceId: string) {
    return {
      url: `${environment.backendURL}/api/source/externalReferences/embeddings/${embeddingId}/${referenceId}`,
    };
  }

  static updateExternalReference(
    sourceId: string,
    reference: TExternalReference
  ): PutRequestData<TExternalReference> {
    return {
      url: `${environment.backendURL}/api/source/externalReferences/${sourceId}/${reference._id}`,
      body: reference,
    };
  }

  static fetchVeevaFacets(): GetRequestData {
    return {
      url: `${environment.backendURL}/api/veeva/status-facets`,
    };
  }

  static fetchVeevaData(param: { [key: string]: string|number }): GetRequestData {
    const query = StringHelper.buildQueryParams(param);
    return {
      url: `${environment.backendURL}/api/veeva/documents/${query}`,
    };
  }

  static importVeevaDataByQuery(
    folderId: string,
    body: {
      [key: string]: string;
    }
  ): PostRequestData<any> {
    return {
      url: `${environment.backendURL}/api/veeva/import`,
      body: {
        folderId,
        ...body,
      },
    };
  }

  static importVeevaData(body: TVeevaImportIdsDto): PostRequestData<TVeevaImportIdsDto> {
    return {
      url: `${environment.backendURL}/api/veeva/importByIds`,
      body,
    };
  }

  static fetchVeevaFacetsTypes(): GetRequestData {
    return {
      url: `${environment.backendURL}/api/veeva/type-facets`,
    };
  }
}
