import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { Subscription } from 'rxjs';

import { TFolderItem } from '@core/interfaces/TFolderItem';
import { TSourceItem } from '@core/interfaces/TSourceItem';

import { TGroup } from '../components/gingr-content/gingr-content.component';
import { ConfirmationModalComponent } from '../components/modals/confirmation-modal/confirmation-modal.component';
import { CommonService } from '@shared/services/common.service';
import { FoldersService } from '@shared/services/folders.service';
import { isCustomIcon, TIconItem } from '@shared/utils/isCustomIcon';
import { isNotNullAndDefined } from '@shared/utils/isNotNullAndDefined';
import { isNullOrEmpty } from '@shared/utils/isNullOrEmpty';
import { SourceFolderModalComponent } from '../components/modals/source-folder-modal/source-folder-modal.component';
import { TMaterialSourceFolder } from '@core/api/materials/types';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ImportSourceSelectorModalComponent } from '../components/modals/import-source-selector/import-source-selector-modal.component';
import { NewFolderModalComponent } from '../components/gingr-new-items-modals/new-folder-modal/new-folder-modal.component';
import { NewECRDataModalComponent } from '../components/modals/new-ecr-data-modal/new-ecr-data-modal.component';
import { NewConfluenceModalComponent } from '../components/gingr-new-items-modals/new-confluence-modal/new-confluence-folder-modal.component';
import { NewVeevaModalComponent } from '../components/gingr-new-items-modals/new-veeva-modal/new-veeva-modal.component';

enum Tabs {
  sources = 0,
  materials = 1,
}

@Component({
  selector: 'app-sources',
  templateUrl: './sources.component.html',
  styleUrls: ['./sources.component.scss'],
})
export class SourcesComponent implements OnInit, OnDestroy {
  public folders: TFolderItem[] = [];
  public foldersLoading = false;
  private _subs: Subscription[] = [];
  public drawerOpened = true;
  public groups: TGroup<TFolderItem>[] = [];
  public selectedFolder: TFolderItem | undefined;
  public selectedSource: TSourceItem | undefined;
  public returnedFromSource = false;
  protected selectedTab: Tabs = Tabs.sources;
  protected selectedMaterialFolder: TMaterialSourceFolder | undefined;

  constructor(
    public dialog: MatDialog,
    private _foldersService: FoldersService,
    private _commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this._subs.push(
      this.route.url.subscribe((url: UrlSegment[]) => {
        const tab: Tabs = Tabs[(url.length === 2 ? url[1] : url[0]).path as keyof typeof Tabs];
        this.navigateToTab(tab);
        this.goToFolderFromUrl();
      })
    );
  }

  public ngOnInit(): void {
    this._subs.push(
      this._foldersService.getFoldersObservable().subscribe(res => {
        this.folders = res;
        this.goToFolderFromUrl();
      })
    );
    this._subs.push(
      this._foldersService.getFoldersLoadingObservable().subscribe(res => {
        this.foldersLoading = res;
      })
    );
    this._foldersService.fetchFolders();
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }

  private goToFolderFromUrl() {
    const folderId = this.route.snapshot.paramMap.get('folder_id');
    if (!folderId) {
      this.selectedFolder = undefined;
    }
    if (this.selectedTab === Tabs.sources) {
      const foundFolder = this.folders.find(item => item._id === folderId);
      if (foundFolder) {
        this.selectedFolder = foundFolder;
      }

      const sourceId = this.route.snapshot.paramMap.get('source_id');

      if (!sourceId) {
        this.selectedSource = undefined;
      }
      if (sourceId) {
        this._foldersService.getSource(sourceId, res => {
          this.selectedSource = res;
        });
      }
    }
  }

  protected addNewItem(): void {
    switch (this.selectedTab) {
      case Tabs.sources:
        this.addNewSourceModal();
        return;
      case Tabs.materials:
        this.addNewMaterialFolder();
        return;
    }
  }

  protected tabChanged(event: MatTabChangeEvent) {
    const path =
      event.tab.textLabel === 'Materials' ? 'sources/materials' : event.tab.textLabel.toLowerCase();
    this.router.navigate([path]);
  }

  protected navigateToTab(tab: Tabs = Tabs.sources) {
    this.selectedTab = tab;
  }

  protected addNewMaterialFolder() {
    const sub = this.dialog
      .open(SourceFolderModalComponent)
      .componentInstance.folderChanged.subscribe(folder => {
        this.selectedMaterialFolder = folder;
        sub.unsubscribe();
      });
  }

  protected addNewSourceModal() {
    const dialogRef = this.dialog.open(ImportSourceSelectorModalComponent);
    const handleOption1Subscription = dialogRef.componentInstance.selectedSource.subscribe(type => {
      switch (type) {
        case 'confluence':
          this.dialog.open(NewConfluenceModalComponent);
          break;
        case 'manual':
          this.dialog.open(NewFolderModalComponent);
          break;
        case 'ecr':
          this.dialog.open(NewECRDataModalComponent);
          break;
        case 'veeva':
          this.dialog.open(NewVeevaModalComponent, { minWidth: '50vw', height: '90vh' });
          break;
      }
      handleOption1Subscription.unsubscribe();
    });
  }

  public hasIcon(item: TFolderItem): boolean {
    return !isNullOrEmpty(item.icon);
  }

  protected isCustomIcon(item: TIconItem): boolean {
    return isCustomIcon(item);
  }

  protected getItemCss(item: TFolderItem): string {
    const classes: string[] = [];
    if (item.tinyIcon === true) {
      classes.push(`menu-icon__tiny`);
    }
    return classes.join(` `);
  }

  protected hasSuffix(item: TFolderItem): boolean {
    return isNotNullAndDefined(item.sufix);
  }

  public openDeleteItemModal(item: TFolderItem): void {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: { text: `Are you sure you want to delete ${item.title}?` },
    });
    const dialogSubscription = dialogRef.componentInstance.actionCallback.subscribe(() => {
      this._foldersService.deleteFolder(item._id, () => {
        dialogRef.close();
        this._commonService.openSuccessSnackBar(`Folder ${item.title} was deleted`);
      });

      dialogSubscription.unsubscribe();
    });
  }

  protected goToFolder(folder: TFolderItem): void {
    this.router.navigate(['/sources', { folder_id: folder._id }]);
  }

  public handleCloseSource(parentFolderId: string): void {
    const foundFolder = this.folders.find(item => item._id === parentFolderId);
    if (foundFolder) {
      this.returnedFromSource = true;
      this.goToFolder(foundFolder);
    }
  }

  protected handleSourceSelected(source: TSourceItem): void {
    this.selectedSource = source;
  }

  protected toggleDrawer(): void {
    this.drawerOpened = !this.drawerOpened;
  }

  protected readonly Tabs = Tabs;
}
