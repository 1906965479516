<div class="new-item-modal inner-bot-modal">
  <h3 class="mat-h3">Add new bot</h3>
  <form [formGroup]="newBotForm">
    <div class="form-inputs">
      <div class="row">
        <div class="col-12">
          <div class="form-field-container">
            <label for="bot-name-input" class="form-field-label">Bot name</label>
            <mat-form-field class="gingr-input-wrapper form-field-full" appearance="outline">
              <input id="bot-name-input" matInput placeholder="Bot name" type="text"
                     formControlName="name" required />
              <mat-error *ngIf="name.invalid">
                <span *ngIf="name.errors?.required">Please enter bot name</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="form-field-container">
            <label for="bot-description-textarea" class="form-field-label">Description</label>
            <mat-form-field class="gingr-input-wrapper form-field-full" appearance="outline">
              <textarea
                        id="bot-description-textarea"
                        matInput
                        placeholder="Description"
                        formControlName="description"
                        rows="3"
                        cols="40"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="form-field-container">
            <label for="bot-presearch-textarea"
                   class="form-field-label d-flex align-items-center">Presearch</label>
            <mat-form-field class="gingr-input-wrapper form-field-full" appearance="outline">
              <textarea
                        id="bot-presearch-textarea"
                        matInput
                        placeholder="Presearch"
                        formControlName="preSearch"
                        rows="2"
                        cols="40"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="form-field-container">
            <label for="bot-instruction-textarea"
                   class="form-field-label d-flex align-items-center">
              Instruction
              <mat-icon class="icon"
                        matTooltip="{{ innerBotMessages.infoInstruction }}">info</mat-icon>
            </label>
            <mat-form-field class="gingr-input-wrapper form-field-full" appearance="outline">
              <textarea
                        id="bot-instruction-textarea"
                        matInput
                        placeholder="Instruction"
                        formControlName="instruction"
                        rows="3"
                        cols="40"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="form-field-container">
            <label for="bot-behaviour-textarea" class="form-field-label d-flex align-items-center">
              Behaviour
              <mat-icon class="icon"
                        matTooltip="{{ innerBotMessages.infoBehaviour }}">info</mat-icon>
            </label>
            <mat-form-field class="gingr-input-wrapper form-field-full" appearance="outline">
              <textarea
                        id="bot-behaviour-textarea"
                        matInput
                        placeholder="Behaviour"
                        formControlName="behaviour"
                        rows="3"
                        cols="40"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="form-field-container">
            <label for="bot-config-textarea" class="form-field-label d-flex align-items-center">
              Config
              <mat-icon class="icon" matTooltip="{{ innerBotMessages.infoConfig }}">info</mat-icon>
            </label>
            <mat-form-field class="gingr-input-wrapper form-field-full" appearance="outline">
              <textarea
                        id="bot-config-textarea"
                        matInput
                        placeholder="Config"
                        formControlName="config"
                        rows="3"
                        cols="40"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-field-container">
            <label class="form-field-label d-flex align-items-center">
              Materials
              <mat-icon class="icon"
                        matTooltip="{{ innerBotMessages.materialsInfo }}">info</mat-icon>
            </label>
            <div class="d-flex">
              <mat-slide-toggle formControlName="display_resources" class="m-l-16" />
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-field-container">
            <label for="top-p-slider" class="form-field-label d-flex align-items-center">
              Top P
              <mat-icon class="icon" matTooltip="{{ innerBotMessages.infoTopP }}">info</mat-icon>
            </label>
            <mat-slider
                        id="top-p-slider"
                        class="gingr-input-wrapper"
                        [min]="inputsConfig.top_p.min"
                        [max]="inputsConfig.top_p.max"
                        [step]="inputsConfig.top_p.step"
                        discrete
                        [displayWith]="formatTopP">
              <input matInput matSliderThumb formControlName="top_p" />
            </mat-slider>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-field-container">
            <label for="temperature-slider" class="form-field-label d-flex align-items-center">
              Temperature
              <mat-icon class="icon"
                        matTooltip="{{ innerBotMessages.infoTemperature }}">info</mat-icon>
            </label>
            <mat-slider
                        id="temperature-slider"
                        class="gingr-input-wrapper"
                        [min]="inputsConfig.temperature.min"
                        [max]="inputsConfig.temperature.max"
                        [step]="inputsConfig.temperature.step"
                        discrete
                        [displayWith]="formatSlider">
              <input matInput matSliderThumb formControlName="temperature" />
            </mat-slider>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-field-container">
            <label for="presence-penalty-slider" class="form-field-label d-flex align-items-center">
              Presence Penalty
              <mat-icon class="icon"
                        matTooltip="{{ innerBotMessages.infoPresencePenalty }}">info</mat-icon>
            </label>
            <mat-slider
                        id="presence-penalty-slider"
                        class="gingr-input-wrapper"
                        [min]="inputsConfig.presence_penalty.min"
                        [max]="inputsConfig.presence_penalty.max"
                        [step]="inputsConfig.presence_penalty.step"
                        discrete
                        [displayWith]="formatSlider">
              <input matInput matSliderThumb formControlName="presence_penalty" />
            </mat-slider>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-field-container">
            <label for="frequency-penalty-slider"
                   class="form-field-label d-flex align-items-center">
              Frequency Penalty
              <mat-icon class="icon"
                        matTooltip="{{ innerBotMessages.infoFrequencyPenalty }}">info</mat-icon>
            </label>
            <mat-slider
                        id="frequency-penalty-slider"
                        class="gingr-input-wrapper"
                        [min]="inputsConfig.frequency_penalty.min"
                        [max]="inputsConfig.frequency_penalty.max"
                        [step]="inputsConfig.frequency_penalty.step"
                        discrete
                        [displayWith]="formatSlider">
              <input matInput matSliderThumb formControlName="frequency_penalty" />
            </mat-slider>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between w-full mt-4">
      <button mat-raised-button color="accent" (click)="showHistoryModal()">Show history</button>

      <button
              mat-raised-button
              color="accent"
              [disabled]="newBotForm.invalid"
              (click)="addOrUpdateBot()">
        Save
      </button>
    </div>
  </form>
</div>