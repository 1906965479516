import { ISourceOriginDriver } from './source-origin-driver.interface';
import { Injectable } from '@angular/core';
import { TSourceItem } from '@core/interfaces/TSourceItem';
import { VeevaService } from '@shared/services/integrations/veeva.service';

@Injectable({
  providedIn: 'root',
})
export class VeevaSourceOrigin implements ISourceOriginDriver {
  source: TSourceItem | undefined;
  baseUrl: string | undefined;

  constructor(private veevaService: VeevaService) {
    this.veevaService.settings$.subscribe(settings => {
      this.baseUrl = settings?.url;
    });
    this.veevaService.loadSettings();
  }

  setSource(source: TSourceItem): void {
    this.source = source;
  }

  hasSourceLink(): boolean {
    return !!this.findVeevaMetadata();
  }

  getSourceUrl(): string | null {
    const item = this.findVeevaMetadata();
    if (item) {
      const globalId = item.value;
      const id = globalId.toString().split('_')[1];
      if(!this.baseUrl) {
        return null;
      }
      console.log(`${this.baseUrl}/ui/#doc_info/${id}`);
      return `${this.baseUrl}/ui/#doc_info/${id}`;
    }
    return null;
  }

  private findVeevaMetadata() {
    return this.source?.metadata.find(it => it.slug === 'veeva_id');
  }
}
