import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastVariant } from '@shared/types/ui.types';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private _snackBar: MatSnackBar) {}

  public getIsLoadingObservable(): Observable<boolean> {
    return this.isLoading.asObservable();
  }

  public openSuccessSnackBar(message: string, duration?: number, actionLabel?: string) {
    return this.openSnackBar(message, actionLabel, duration, 'success');
  }

  public openWarningSnackBar(message: string, duration?: number, actionLabel?: string) {
    return this.openSnackBar(message, actionLabel, duration, 'warning');
  }

  public openErrorSnackBar(message: string, duration?: number, actionLabel?: string) {
    return this.openSnackBar(message, actionLabel, duration, 'error');
  }

  public openSnackBar(
    message: string,
    actionLabel = '✕',
    duration = 3000,
    variant: ToastVariant = 'default'
  ) {
    return this._snackBar.open(message, actionLabel, {
      duration,
      panelClass: variant === 'default' ? undefined : variant,
    });
  }

  setIsLoading(isLoading: boolean): void {
    this.isLoading.next(isLoading);
  }
}
