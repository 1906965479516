import { Inject, Injectable, Injector } from '@angular/core';
import { environment } from '@env/environment';
import { INoticeDriver } from './drivers/notice-driver.interface';
import { TakedaNoticeDriver } from './drivers/takeda-notice.driver';

@Injectable({
  providedIn: 'root',
})
export class NoticeService {
  private _driver: INoticeDriver | undefined;

  constructor(@Inject(Injector) private injector: Injector) {
    this.setDriver(environment.brand);
  }

  private setDriver(brand: string): void {
    switch (brand) {
      case 'takeda':
        this._driver = this.injector.get(TakedaNoticeDriver);
        break;
      // Add other brand drivers here in the future
    }
  }

  showNoticeAfterLogin(): void {
    this._driver?.showNoticeAfterLogin();
  }
}
