import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { TFolderItem } from '@core/interfaces/TFolderItem';
import { TSourceItem } from '@core/interfaces/TSourceItem';

import { CommonService } from '@shared/services/common.service';
import { FoldersService } from '@shared/services/folders.service';
import { allowedStatuses } from 'app/constants/status';

import { CompareSourcesModalComponent } from '../modals/compare-sources-modal/compare-sources-modal.component';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { SourceSortField } from '@core/interfaces/TSources';
import { PAGE_SIZES, TPaging } from '@core/interfaces/TPaging';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-folder-sources-list',
  templateUrl: './folder-sources-list.component.html',
  styleUrls: ['./folder-sources-list.component.scss'],
})
export class FolderSourcesListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public isLoading = true;
  @Input() public folder: TFolderItem = {} as TFolderItem;
  @Input() public folderSources: TSourceItem[] = [];
  @Input() public paging: TPaging = {} as TPaging;
  @Input() public sorting: { sortField: SourceSortField; sortDirection: SortDirection } = {
    sortField: 'name',
    sortDirection: 'asc',
  };
  @Output() public sourceSelected = new EventEmitter<TSourceItem>();
  @Output() public sortChanged = new EventEmitter<{
    sortField: SourceSortField;
    sortDirection: SortDirection;
  }>();
  @Output() public pageChanged = new EventEmitter<TPaging>();
  @ViewChild('table') table: MatTable<TSourceItem> = {} as MatTable<TSourceItem>;
  @ViewChild(MatSort) sort: MatSort = {} as MatSort;
  dataSource = new MatTableDataSource<TSourceItem>([]);

  selectedSource: TSourceItem | null = null;
  allowedStatuses = allowedStatuses;
  pageSizes = PAGE_SIZES;

  private _subs: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private _commonService: CommonService,
    private _folderService: FoldersService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._subs.push(
      this._folderService.getFoldersObservable().subscribe(res => {
        this.folder = res.find(folder => folder._id === this.folder._id) as TFolderItem;
      })
    );
  }

  ngOnChanges(): void {
    this.dataSource.data = this.folderSources;
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }

  public openCompareSourceModal(item: TSourceItem): void {
    this.dialog.open(CompareSourcesModalComponent, {
      data: { source: item },
    });
  }

  public openDeleteSourceModal(item: TSourceItem): void {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: { text: `Are you sure you want to delete ${item.name}?` },
    });
    const dialogSubscription = dialogRef.componentInstance.actionCallback.subscribe(() => {
      this._folderService.deleteSource(item, () => {
        dialogRef.close();
        this._commonService.openSuccessSnackBar(`Source ${item.name} was deleted`);
      });
      dialogSubscription.unsubscribe();
    });
  }

  public handlePageEvent(e: PageEvent): void {
    this.pageChanged.emit({ total: e.length, limit: e.pageSize, page: e.pageIndex + 1 });
  }

  public handleSortChange(sortState: Sort): void {
    this.sortChanged.emit({
      sortDirection: sortState.direction,
      sortField: sortState.active as SourceSortField,
    });
    this.dataSource.sort = this.sort;
  }

  public openSourceDetails(source: TSourceItem): void {
    this.router.navigate(['/sources', { folder_id: this.folder._id, source_id: source._id }]);
  }

  public handleCloseSource(): void {
    this.selectedSource = null;
  }

  toggleStatus(row: TSourceItem): void {
    row.isProcessing = true;
    const targetStatus =
      row.status === allowedStatuses.ACTIVE ? allowedStatuses.INACTIVE : allowedStatuses.ACTIVE;
    this._folderService.updateFolderSource(
      { _id: row._id, status: targetStatus },
      isUpdateSuccessfully => {
        if (isUpdateSuccessfully) {
          row.status = targetStatus;
          this._commonService.openSuccessSnackBar('Source status updated');
        } else {
          this._commonService.openErrorSnackBar('Source status update failed');
        }
        row.isProcessing = false;
      }
    );
  }
}
