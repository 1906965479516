import { HttpClient } from '@angular/common/http';
import { ISSODriver } from '@core/authentication/sso/drivers/sso-driver.interface';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SSORedirectUrl } from '@core/authentication/sso/sso.type';
import { AuthApi } from '@core/api/auth/auth-api';

@Injectable({
  providedIn: 'root',
})
export class SSOOcta implements ISSODriver {
  constructor(private http: HttpClient) {}

  initLogin(): Observable<SSORedirectUrl> {
    const { url } = AuthApi.octaRedirection();
    return this.http.get<SSORedirectUrl>(url);
  }
}
