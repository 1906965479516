import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MetadataService, MetadataSlugs } from '@shared/services/metadata.service';
import { CommonService } from '@shared/services/common.service';
import { Subscription } from 'rxjs';
import { defaultDashboardContent } from '../../../../../constants/defaults';

@Component({
  selector: 'app-dashboard-content',
  templateUrl: './dashboard-content.component.html',
  styleUrls: ['./dashboard-content.component.scss'],
})
export class DashboardContentComponent implements OnInit, OnDestroy {
  protected htmlContent: string | null = null;
  private _sub: Subscription | undefined;
  protected config: AngularEditorConfig = {
    editable: true,
    sanitize: false,
    outline: false,
    toolbarHiddenButtons: [
      [
        // 'heading',
        'fontName',
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        // 'link',
        // 'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        // 'removeFormat',
        // 'toggleEditorMode',
      ],
    ],
  };

  constructor(
    private _metadataService: MetadataService,
    private _commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.getContent();
  }

  getContent() {
    this._metadataService.get<string>(MetadataSlugs.DashboardContent).subscribe({
      next: metadata => {
        if (metadata.value) {
          this.htmlContent = metadata.value;
        } else {
          this.htmlContent = defaultDashboardContent;
        }
      },
      error: () => {
        this.htmlContent = defaultDashboardContent;
      },
    });
  }

  save() {
    this._sub = this._metadataService
      .set(MetadataSlugs.DashboardContent, this.htmlContent)
      .subscribe({
        next: () => {
          this._commonService.openSuccessSnackBar('Successfully saved!');
        },
        error: () => {
          this._commonService.openErrorSnackBar('Error occurred');
        },
      });
  }

  ngOnDestroy(): void {
    this._sub?.unsubscribe();
  }
}
