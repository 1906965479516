import {
  ConversationData,
  ProcessedText,
  ProcessedTextReference,
} from '@core/interfaces/TResultsItem';
import { TAdaptedMessage, TAdaptedReference } from '@shared/services/bots/types/types';
import { ReferenceDetail, TSourceDetail } from '@core/interfaces/TMessage';

export class ReferenceConverter {
  private embeddingIndexDictionary: Map<string, number>;

  constructor() {
    this.embeddingIndexDictionary = new Map<string, number>();
  }

  convert(data: ConversationData, adaptedMessage: TAdaptedMessage): TAdaptedMessage {
    data.processedTexts.forEach((processedTxt, index) => {
      this.setParagraphText(adaptedMessage, index, processedTxt);
      this.convertReferences(processedTxt, adaptedMessage, data, index);
      adaptedMessage.paragraphs[index].references?.sort((a, b) => a.order - b.order);
    });

    return adaptedMessage;
  }

  convertWithIndexedParagraph(
    dataFromApi: ConversationData,
    adaptedMessage: TAdaptedMessage,
    paragraphIndex: number
  ): TAdaptedMessage {
    const processedTxt = dataFromApi.processedTexts[0];
    this.convertReferences(processedTxt, adaptedMessage, dataFromApi, paragraphIndex);

    dataFromApi.processedTexts.forEach((processedTxt, index) => {
      adaptedMessage.paragraphs[index].references?.sort((a, b) => a.order - b.order);
    });

    return adaptedMessage;
  }

  private setParagraphText(
    adaptedMessage: TAdaptedMessage,
    index: number,
    processedTxt: ProcessedText
  ) {
    if (adaptedMessage.paragraphs[index]) {
      adaptedMessage.paragraphs[index].text = processedTxt.segment;
    } else {
      adaptedMessage.paragraphs.push({
        text: processedTxt.segment,
        references: [],
      });
    }
  }

  private convertReferences(
    processedTxt: ProcessedText,
    adaptedMessage: TAdaptedMessage,
    dataFromApi: ConversationData,
    index: number
  ) {
    processedTxt.references.forEach(reference => {
      const sourceIndex = adaptedMessage.sources.findIndex(
        source => source._id === reference.sourceId
      );

      if (!this.embeddingIndexDictionary.has(reference.embeddingId)) {
        this.embeddingIndexDictionary.set(
          reference.embeddingId,
          this.embeddingIndexDictionary.size + 1
        );
      }

      const adoptedReference = {
        order: this.embeddingIndexDictionary.get(reference.embeddingId),
        source: { ...adaptedMessage.sources[sourceIndex] } || ({} as TSourceDetail),
      } as TAdaptedReference;

      adoptedReference.source.embeddingContent = this.retrieveHighlightText(dataFromApi, reference);
      adoptedReference.source.externalRefsToDisplay = reference.externalReferences;

      if (!adaptedMessage.paragraphs[index].references) {
        adaptedMessage.paragraphs[index].references = [];
      }

      adaptedMessage.paragraphs[index].references?.push(adoptedReference);

      const sourceDetail = adaptedMessage.sources[sourceIndex];
      const refIndex = this.embeddingIndexDictionary.get(reference.embeddingId);
      if (refIndex !== undefined) {
        const referenceDetail: ReferenceDetail = {
          textToHighlight: adoptedReference.source.embeddingContent,
          position: refIndex,
          externalReference: 'not implemented',
          reasoning: reference.reasoning,
          accuracy: reference.accuracy,
        };
        if (sourceDetail) {
          sourceDetail.referenceIndexes!.set(refIndex, referenceDetail);
        }
      }
    });
  }

  retrieveHighlightText(data: ConversationData, ref: ProcessedTextReference): string {
    if (ref.originalText) {
      return ref.originalText;
    }
    if (ref.embeddingContent) {
      return ref.embeddingContent;
    }
    if (data.embeddingContent) {
      const embeddingDetails = data.embeddingContent[ref.sourceId as string];
      if (embeddingDetails) {
        const detail = embeddingDetails.find(detail => detail.embeddingId === ref.embeddingId);
        if (detail) {
          return detail.content;
        }
      }
    }
    return '';
  }
}
