import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { filter } from 'rxjs/operators';
import { AuthService } from '@core/authentication';
import { NoticeService } from '@shared/services/notice/notice.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public isSubmitting = false;

  public loginForm = this.fb.nonNullable.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
    rememberMe: [true],
  });
  public twoFactorAuthForm = this.fb.nonNullable.group({
    token: ['', [Validators.required, Validators.maxLength(6), Validators.maxLength(6)]],
  });
  token2faError: string | undefined;
  is2FaRequired = false;
  credentialsError: string | undefined;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private auth: AuthService,
    private noticeService: NoticeService
  ) {}

  get username() {
    return this.loginForm.get('username')!;
  }

  get password() {
    return this.loginForm.get('password')!;
  }

  get rememberMe() {
    return this.loginForm.get('rememberMe')!;
  }

  login() {
    this.isSubmitting = true;
    this.credentialsError = undefined;
    this.auth
      .login(this.username.value, this.password.value, this.rememberMe.value)
      .pipe(
        filter((result: any) => {
          if (result.required2Fa) {
            this.is2FaRequired = true;
          } else {
            return result.authenticated;
          }
        })
      )
      .subscribe(
        () => {
          this.noticeService.showNoticeAfterLogin();
          this.router.navigateByUrl('/');
        },
        (errorRes: HttpErrorResponse) => {
          if (errorRes.status === 400) {
            this.credentialsError = 'Incorrect credentials';
          }
          this.isSubmitting = false;
        }
      );
  }

  verify2Fa() {
    this.token2faError = undefined;
    this.isSubmitting = true;
    this.auth.verify2FaCode(this.twoFactorAuthForm.controls.token.value).subscribe({
      error: (err: HttpErrorResponse) => {
        this.token2faError = err.error.message;
      },
      next: result => {
        this.auth.setToken(result.token);
        this.noticeService.showNoticeAfterLogin();
      },
      complete: () => {
        this.isSubmitting = false;
      },
    });
  }
}
