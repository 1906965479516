import { HttpClient } from '@angular/common/http';
import { ISSODriver } from '@core/authentication/sso/drivers/sso-driver.interface';
import { SSORedirectUrl } from '@core/authentication/sso/sso.type';
import { Injectable } from '@angular/core';
import { AuthApi } from '@core/api/auth/auth-api';

@Injectable({
  providedIn: 'root',
})
export class SSOMicrosoft implements ISSODriver {
  constructor(private http: HttpClient) {}

  initLogin() {
    const { url } = AuthApi.microsoftRedirection();
    return this.http.get<SSORedirectUrl>(url);
  }
}
