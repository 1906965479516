import { Directive, Input, TemplateRef, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '@core';

@Directive({
  selector: '[minRole]',
})
export class MinRoleDirective implements OnInit, OnDestroy {
  private subscription: Subscription | undefined;
  private hasView = false;
  
  @Input() minRole = '';

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.subscription = this.authService.user().subscribe(user => {
      const isVisible = this.checkRole(user, this.minRole);
      
      if (isVisible && !this.hasView) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      } else if (!isVisible && this.hasView) {
        this.viewContainer.clear();
        this.hasView = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private checkRole(user: any, role: string): boolean {
    if (!role) {
      return true;
    }
    
    if (user.roles?.includes('admin')) {
      return true;
    } else if (user.roles?.includes('user') && role !== 'admin') {
      return true;
    }
    return false;
  }
}
