import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AbstractsService } from '../../features/slr/services/abstract.service';

export interface UploadedFile {
  file: File;
  url: string;
  rowIndex: number;
  itemId: string;
}

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  private uploadedFiles = new Map<string, UploadedFile>();
  private filesSubject = new BehaviorSubject<Map<string, UploadedFile>>(this.uploadedFiles);

  files$ = this.filesSubject.asObservable();

  constructor(private abstractService: AbstractsService) {}

  uploadFile(file: File, rowIndex: number, itemId: string) {
    // Create a unique key for the file
    const key = `${itemId}_${rowIndex}`;

    const url = URL.createObjectURL(file);

    // Store file info
    this.uploadedFiles.set(key, { file, url, rowIndex, itemId });
    this.filesSubject.next(this.uploadedFiles);

    return this.abstractService.uploadFiles(file, parseInt(itemId));
  }

  getFile(itemId: string, rowIndex: number): UploadedFile | undefined {
    const key = `${itemId}_${rowIndex}`;
    return this.uploadedFiles.get(key);
  }

  hasFile(itemId: string, rowIndex: number): boolean {
    const key = `${itemId}_${rowIndex}`;
    return this.uploadedFiles.has(key);
  }

  openFile(itemId: string, rowIndex: number): void {
    const file = this.getFile(itemId, rowIndex);
    if (file) {
      window.open(file.url, '_blank');
    }
  }

  deleteFile(itemId: string, rowIndex: number): void {
    const key = `${itemId}_${rowIndex}`;
    const file = this.uploadedFiles.get(key);
    if (file) {
      URL.revokeObjectURL(file.url);
      this.uploadedFiles.delete(key);
      this.filesSubject.next(this.uploadedFiles);
      console.log(`Mock delete: File for item ${itemId}`);
    }
  }

  // Clean up when component is destroyed
  cleanup(): void {
    this.uploadedFiles.forEach(file => {
      URL.revokeObjectURL(file.url);
    });
    this.uploadedFiles.clear();
    this.filesSubject.next(this.uploadedFiles);
  }
}
