import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TSourceItem } from '@core/interfaces/TSourceItem';
import { CommonService } from '@shared/services/common.service';
import { BrowserStorageService } from '@shared/services/browser-storage.service';

@Injectable({
  providedIn: 'root',
})
export class LocalSourcesService {
  public readonly maxListItems: number = 5;
  private sourcesByBotId: Map<string, TSourceItem[]> = new Map();
  private sourceListSubjects: Map<string, BehaviorSubject<TSourceItem[]>> = new Map();

  constructor(
    private _commonService: CommonService,
    private _storage: BrowserStorageService
  ) {}

  private getStorageKey(botId: string): string {
    return `bot_sources_${botId}`;
  }

  private loadSourcesForBot(botId: string): TSourceItem[] {
    // Try to get sources from storage for this specific bot
    const savedSources = this._storage.get<TSourceItem[]>(this.getStorageKey(botId));
    if (savedSources) {
      // Update both the map and subject with loaded sources
      this.sourcesByBotId.set(botId, savedSources);
      if (this.sourceListSubjects.has(botId)) {
        this.sourceListSubjects.get(botId)!.next(savedSources);
      }
      return savedSources;
    }
    return [];
  }

  private getOrCreateSubject(botId: string): BehaviorSubject<TSourceItem[]> {
    if (!this.sourceListSubjects.has(botId)) {
      // Always load fresh sources when creating a new subject
      const sources = this.loadSourcesForBot(botId);
      this.sourceListSubjects.set(botId, new BehaviorSubject<TSourceItem[]>(sources));
      this.sourcesByBotId.set(botId, sources);
    } else {
      // Refresh sources even if subject exists
      this.loadSourcesForBot(botId);
    }
    return this.sourceListSubjects.get(botId)!;
  }

  addItem(botId: string, item: TSourceItem): boolean {
    if (!this.isValid(botId, item)) return false;

    // Load current sources first to ensure we have latest data
    const currentSources = this.loadSourcesForBot(botId);
    const sources = [...currentSources, item];

    this.sourcesByBotId.set(botId, sources);
    this._storage.put(this.getStorageKey(botId), sources);
    this.getOrCreateSubject(botId).next(sources);
    return true;
  }

  removeItem(botId: string, item: TSourceItem) {
    // Load current sources first to ensure we have latest data
    const currentSources = this.loadSourcesForBot(botId);
    const updatedSources = currentSources.filter(it => it._id !== item._id);

    this.sourcesByBotId.set(botId, updatedSources);
    this._storage.put(this.getStorageKey(botId), updatedSources);
    this.getOrCreateSubject(botId).next(updatedSources);
  }

  getItems(botId: string) {
    // Always ensure we have latest data when getting items
    this.loadSourcesForBot(botId);
    return this.getOrCreateSubject(botId).asObservable();
  }

  clearItems(botId: string) {
    const emptySources: TSourceItem[] = [];
    this.sourcesByBotId.set(botId, emptySources);
    this._storage.put(this.getStorageKey(botId), emptySources); // Store empty array instead of removing

    // Ensure we update the subject if it exists
    if (this.sourceListSubjects.has(botId)) {
      this.sourceListSubjects.get(botId)!.next(emptySources);
    }
  }

  switchBot(fromBotId: string | null, toBotId: string) {
    // Don't clear storage when switching, just refresh the subjects
    if (fromBotId) {
      this.sourceListSubjects.delete(fromBotId);
    }
    // Load sources for new bot
    this.loadSourcesForBot(toBotId);
  }

  isItemInList(botId: string, id: string): boolean {
    // Always check storage first to ensure we have latest data
    const sources = this.loadSourcesForBot(botId);
    return sources.some(item => item._id === id);
  }

  private isValid(botId: string, element: TSourceItem): boolean {
    // Always check storage first to ensure we have latest data
    const sources = this.loadSourcesForBot(botId);

    if (sources.length >= this.maxListItems) {
      this._commonService.openWarningSnackBar(
        `Maximum items of ${this.maxListItems} reached please remove some to add more`
      );
      return false;
    }

    if (sources.some(i => i._id === element._id)) {
      this._commonService.openErrorSnackBar('Element exists on the list');
      return false;
    }
    return true;
  }
}
