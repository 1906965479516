import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

export interface SourceViewerData {
  sources: {
    index: number;
    preview: string;
    fullPreview: string;
    url?: string;
  }[];
  activeIndex?: number;
}

@Component({
  selector: 'app-source-viewer-sidebar',
  templateUrl: './source-viewer-sidebar.component.html',
  styleUrls: ['./source-viewer-sidebar.component.scss']
})
export class SourceViewerSidebarComponent implements OnChanges {
  @Input() data: SourceViewerData = { sources: [] };
  @Input() visible = false;
  @Output() closeSidebar = new EventEmitter<void>();
  
  selectedSourceIndex = 0;
  totalSources = 0;
  isExpanded = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && this.data) {
      this.totalSources = this.data.sources.length;
      
      // Set the active source if provided
      if (this.data.activeIndex !== undefined && 
          this.data.activeIndex >= 0 && 
          this.data.activeIndex < this.data.sources.length) {
        this.selectedSourceIndex = this.data.activeIndex;
      }
    }
  }

  closeDialog(): void {
    this.closeSidebar.emit();
  }

  toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
  }
} 