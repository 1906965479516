<div class="d-flex align-items-center quiz-content">
  <div class="quiz-question">
    <ng-container *ngFor="let n of [].constructor(currentQuestionIndex); let i = index">

      <div class="message-container">
        <span class="text">{{ questions[i].question }}</span>
      </div>
      <div class="answers-container">

        <button *ngFor="let answer of questions[i].answers"
                class="w-full answer-button history-answer"
                mat-raised-button
                color="accent"
                (click)="checkAnswer(questions[i], answer, i)"
                [disabled]="questions[i].disabled || asResults"
                [ngClass]="getAnswerClass(questions[i], answer)"
        >
          {{ answer.answer }}
        </button>
      </div>
      <p *ngIf="questions[i].correctAnswered !== undefined" class="answer-result"
         [ngClass]="{correct: questions[i].correctAnswered}">
        <app-quiz-reference
          [answer]="questions[i].correctAnswered ? questions[i].good_answer_reply : questions[i].wrong_answer_reply"
          [sourcesDetails]="sourcesDetails"></app-quiz-reference>
      </p>
    </ng-container>

  </div>
</div>

