import { Component, Input, OnInit } from '@angular/core';
import { TSourceDetail } from '@core/interfaces/TMessage';
import { SourceViewerData } from './source-viewer-sidebar.component';
import { StringHelper } from '../../../../../features/helpers/string.helper';
interface SourceDisplay {
  preview: string;
  fullPreview: string;
}

@Component({
  selector: 'app-original-content',
  templateUrl: './original-content.component.html',
  styleUrls: ['./original-content.component.scss'],
})
export class OriginalContentComponent implements OnInit {
  @Input() content: string[] | string | undefined = undefined;
  @Input() sources: TSourceDetail[] = [];

  displaySources: SourceDisplay[] = [];
  moreSources: SourceDisplay[] = [];
  hasMoreSources = false;

  // Source viewer sidebar properties
  sidebarVisible = false;
  sidebarData: SourceViewerData = { sources: [] };
  activeSourceIndex = 0;

  ngOnInit(): void {
    // Process content if it exists
    if (this.content) {
      this.processContent();
    }
    // If no sources are provided, use mock data
    else if (!this.sources || this.sources.length === 0) {
      this.processSources();
    }
  }

  private processContent(): void {
    const contentArray = Array.isArray(this.content) ? this.content : [this.content];

    contentArray.forEach((text, index) => {
      if (text) {
        // Parse the structured content
        const parts = text.split(/[\n\r]+/);
        let title = '';
        const content = text;

        // Find the title (source) part
        const sourceIndex = parts.findIndex(part => part.startsWith('Source:'));
        if (sourceIndex !== -1) {
          title = parts[sourceIndex].replace('Source:', '').trim();
        }

        const sourceDisplay: SourceDisplay = {
          preview: title || this.truncateText(content), // Use title as preview if available, otherwise truncate content
          fullPreview: content,
        };

        if (index < 3) {
          this.displaySources.push(sourceDisplay);
        } else {
          this.moreSources.push(sourceDisplay);
        }
      }
    });

    this.hasMoreSources = this.moreSources.length > 0;
  }

  // Open source viewer sidebar
  openSourceViewer(sourceIndex: number): void {
    const allSources = [...this.displaySources, ...this.moreSources];

    // Prepare data for the sidebar
    this.sidebarData = {
      sources: allSources.map((source, index) => ({
        index: index + 1,
        preview: source.preview,
        fullPreview: source.fullPreview,
      })),
      activeIndex: sourceIndex,
    };

    this.activeSourceIndex = sourceIndex;
    this.sidebarVisible = true;
  }

  // Close the sidebar
  closeSidebar(): void {
    this.sidebarVisible = false;
  }

  // Truncate text to approximately 10 words
  private truncateText(text: string): string {
    return StringHelper.truncateToWords(text);
  }

  private processSources(): void {
    // Process real sources
    this.sources.forEach((source, index) => {
      const fullText = source.references || 'No preview available';

      const sourceDisplay: SourceDisplay = {
        preview: this.truncateText(fullText),
        fullPreview: fullText,
      };

      if (index < 3) {
        this.displaySources.push(sourceDisplay);
      } else {
        this.moreSources.push(sourceDisplay);
      }
    });

    this.hasMoreSources = this.moreSources.length > 0;
  }
}
