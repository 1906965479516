import { Injectable } from '@angular/core';
import { BaseApiService } from '../base-api.service';
import { SLRCreateProjectDto, SLRProjectDto } from '../../dtos/projects.dto';

@Injectable({ providedIn: 'root' })
export class ApiProjectsService extends BaseApiService {
  protected apiPath = 'projects';

  create(project: SLRCreateProjectDto) {
    return this.post<SLRCreateProjectDto, SLRProjectDto>('', project);
  }

  update(project: SLRProjectDto) {
    return this.put<SLRProjectDto, SLRProjectDto>(project.projectId.toString(), project);
  }

  index() {
    return this.get<SLRProjectDto[]>('');
  }

  getByID(id: number) {
    return this.get<SLRProjectDto>(id.toString());
  }
}
