<form class="form-field-full" [formGroup]="vocabularyRulesForm">
  <div class="form-field-container">
    <label for="vocabulary-rules-textarea" class="form-field-label">Vocabulary Rules</label>
    <mat-form-field class="gingr-input-wrapper w-full" appearance="outline">
      <textarea
                id="vocabulary-rules-textarea"
                placeholder="Enter vocabulary rules"
                matInput
                formControlName="rules"
                required
                rows="10"></textarea>
    </mat-form-field>
  </div>
  <button
          mat-raised-button
          color="accent"
          [disabled]="vocabularyRulesForm.invalid"
          (click)="submit()">
    Save
  </button>
</form>