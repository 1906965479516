<div class="source-viewer-sidebar" [class.visible]="visible" [class.expanded]="isExpanded">
  <div class="sidebar-header">
    <div class="source-title">
      <mat-icon>description</mat-icon>
      <span>{{ totalSources }} sources</span>
    </div>
    <div class="header-actions">
      <button mat-icon-button (click)="toggleExpand()"
              [attr.aria-label]="isExpanded ? 'Collapse sidebar' : 'Expand sidebar'">
        <mat-icon>{{ isExpanded ? 'chevron_right' : 'chevron_left' }}</mat-icon>
      </button>
      <button mat-icon-button (click)="closeDialog()" aria-label="Close">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div class="source-selector">
    <mat-form-field appearance="outline">
      <mat-select [(value)]="selectedSourceIndex">
        <mat-option *ngFor="let source of data.sources; let i = index" [value]="i">
          Source {{ source.index }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="source-content" *ngIf="data.sources[selectedSourceIndex]">
    <div class="source-text">
      <p>{{ data.sources[selectedSourceIndex].fullPreview }}</p>
    </div>
  </div>
</div>