import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

export type TSourcesTypes = 'manual' | 'ecr' | 'confluence' | 'veeva'

@Component({
  selector: 'app-import-source-selector-modal',
  templateUrl: './import-source-selector-modal.component.html',
  styleUrls: ['./import-source-selector-modal.component.scss'],
})
export class ImportSourceSelectorModalComponent {
  @Output() public selectedSource: EventEmitter<TSourcesTypes> = new EventEmitter();

  constructor(
    private _dialogRef: MatDialogRef<ImportSourceSelectorModalComponent>,
    private _router: Router,
  ) {
  }

  public handleOption1Click(source: TSourcesTypes): void {
    this._dialogRef.close();
    this.selectedSource.emit(source);
  }

  protected goToSettings() {
    this._dialogRef.close();
    this._router.navigateByUrl(`/profile/api-settings`);
  }
}
